import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import FeedbackDocumentation from './components/feedback';

const HelpDashboard = () => {

    const [BasicTab, setBasicTab] = useState('0');

    const tabList = [
        {
            title: 'Feedback',
            component: <FeedbackDocumentation />
        }
    ]

    useEffect(() => { 
        document.title = 'Need Help?'
    }, []);

    return (
        <Fragment>
            <Col sm="12" xl="12" className='xl-100' >
                <Card>
                    <CardBody>
                        <Nav tabs>
                            { tabList.map((tab, i) => 
                                <NavItem key={tab.title}>
                                    <NavLink href="#" className={BasicTab === String(i) ? 'active' : ''} onClick={() => setBasicTab(String(i))}>{tab.title}</NavLink>
                                </NavItem>
                            )}
                        </Nav>
                        <TabContent activeTab={BasicTab}>
                            { tabList.map((tab, i) =>
                                (
                                    <TabPane className="fade show" tabId={String(i)} key={tab.title + i}>
                                        {tab.component}
                                    </TabPane>
                                )
                            )}   
                        </TabContent>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};
export default HelpDashboard;