import React, { useContext, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Image } from '../../AbstractElements';
import CheckContext from '../../_helper/Customizer';
import { AccountContext } from '../../GlobalComponents/Authentication/Accounts';

const Leftbar = () => {

    const { userSession } = useContext(AccountContext); 

    const { mixLayout, toggleSidebar } = useContext(CheckContext);  
    const [toggle, setToggle] = useState(false);

    const openCloseSidebar = () => { 
        setToggle(!toggle);
        toggleSidebar(toggle);
    };

    return (
        <Fragment>
            <div className="main-header-left">
                {mixLayout ?
                    <div className="logo-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                            <Image attrImage={{ className: 'img-fluid d-inline', src: 'https://s3.amazonaws.com/portal.nexnow.io/NexNow_Logo_clear.png', alt: '' }} />
                        </Link>
                    </div>
                    :
                    <div className="dark-logo-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                            <Image attrImage={{ className: 'img-fluid d-inline', src: 'https://s3.amazonaws.com/portal.nexnow.io/NexNow_Logo_clear.png', alt: '' }} />
                        </Link>
                    </div>
                }
            </div>
        </Fragment >
    );
};

export default Leftbar;