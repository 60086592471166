import React, { Fragment, useEffect } from 'react';
import { FormOptionsBuilder, RegisteredInputsBuilder, ButtonBuilder } from '../../../../../../GlobalComponents/Builders';
import { buildOptionsFromArray, setInitialFormValues } from '../../../../../../GlobalComponents/Helpers';
import { Row, Card, CardBody, Form, Col, CardFooter } from 'reactstrap'
import { useForm } from 'react-hook-form';

const BackorderNotificationSurveyJob = ({jobData, connectionData, jobSettings}) => {
    
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const handleJobStart = () => {}

    const handleJobPreview = () => {
        window.location.href = process.env.PUBLIC_URL + '/surveys?request_id=' + '025b3ef7-24a8-4f5f-8d6b-739c68de0ee3'
    }

    useEffect(() => {

        var intialValues = {}
        
        jobSettings.map(setting => {
            intialValues[setting.name] = setting.value
        }); 

        setInitialFormValues(intialValues, setValue)

    }, []);

    return (
        <Fragment>
            <Row>
                <Col xl="6">
                    <Form onSubmit={handleSubmit(() => {})}>
                        <Card>
                            <CardBody>
                                <h4>Job Settings</h4>
                                <br/>
                                {jobData && jobData.destination_connection ? (<p><strong>Connection: </strong>{' ' + jobData.destination_connection.name}</p>) : ''}
                                <br/>
                                <RegisteredInputsBuilder 
                                    registrator={register}
                                    registratorErrors={errors}
                                    inputs={
                                        jobSettings.map(setting => {

                                            return {
                                                label: setting.label,
                                                size: 12,
                                                inputSize: 6,
                                                name: setting.name,
                                                type: setting.datatype,
                                                helpText: setting.helpText,
                                                options: setting.options ? <FormOptionsBuilder options={buildOptionsFromArray(setting.options, { label: 'label', value: 'id'})}/> : null
                                            }
                                        })
                                    }
                                /> 
                            </CardBody>
                            <CardFooter className="text-end">
                                <ButtonBuilder label='Update Connection' form=''/>
                            </CardFooter>
                        </Card>
                    </Form>
                </Col>
                <Col xl="6">
                    <Card>
                        <CardBody>
                            <h4>Job Actions</h4>
                            <br/>
                            <p>Currently Disabled</p>
                            <br/>
                            <p>Last Run: 04-05-23</p>
                            <br/>
                            <ButtonBuilder label='Enable Job' className='m-2' onClick={handleJobStart}/>
                            <ButtonBuilder label='View Survey' onClick={handleJobPreview}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
};

export default BackorderNotificationSurveyJob;