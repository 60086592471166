
import { AccountContext } from '../../GlobalComponents/Authentication/Accounts';
import React, { useContext } from 'react';
import PartnerSubscriptionList from './Subscription/Partner';
import GlobalSubscriptionList from './Subscription/Global';

const SubscriptionList = ({}) => {

    document.title = 'SubscriptionList'

    const { userSession } = useContext(AccountContext);

    switch (userSession.access_level) {
        case 'global':
            return <GlobalSubscriptionList/>
        default:
            return <PartnerSubscriptionList/>
    }
}

export default SubscriptionList