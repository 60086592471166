import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { ApiRequestBuilder } from "../../../../../../GlobalComponents/Builders";
import { sortArrayByKey } from '../../../../../../GlobalComponents/Helpers';
import DMTSearch from "./components/DMTSearch";
import ItemEntry from './components/OrderEntryView';

const Subpage = () => {

    let { toolId, jobId, subpage } = useParams();

    const [jobData, setJobData] = useState([]);
    const [toolData, setToolData] = useState({});
    const [renderPage, setRenderPage] = useState(false);
    const [connectionData, setConnectionData] = useState([]);

    useEffect(() => {
    
        ApiRequestBuilder('toolsToolIdGet', { toolId }).then(function(results){
            setToolData(results.data ? results.data : {})
        })

        ApiRequestBuilder('connectionsGet')
            .then(function(results) {
                results.data && results.data.length > 0 ? setConnectionData(sortArrayByKey(results.data, 'name')) : null
            }
        )

        ApiRequestBuilder('toolsToolIdJobsJobIdGet', { toolId, jobId }).then(function(results){
            if (results.data)
            {
                setJobData(results.data)
                setRenderPage(true)
            }
        })

    }, [renderPage]);

    const renderToolPage = () => {
        switch(subpage)
        {
            case 'dmt-search': 
                return <DMTSearch toolData={toolData} jobData={jobData} connectionData={connectionData} />
            case 'orders': 
                return <ItemEntry toolData={toolData} jobData={jobData} connectionData={connectionData} />
            default:
                return <p>I don't think you are supposed to be here</p>
        }
    }

    return renderPage ? renderToolPage() : <p>Loading...</p>
};

export default Subpage;
