import React, { Fragment } from 'react';
import { Suspense, useEffect, useState, useContext } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useParams } from 'react-router-dom';
import Loader from '../Layout/Loader';
import LayoutRoutes from '../Route/LayoutRoutes';
import LoginForm, { SignUpForm, ForgotPasswordForm } from '../GlobalComponents/Authentication/';
import { AccountContext } from '../GlobalComponents/Authentication/Accounts';
import Survey from '../Components/Surveys';

const Routers = () => {

        const [authenticated, setAuthenticated] = useState(false);
        const [authenticating, setAuthenticating] = useState(true);

        /**
         * This variable is used to determine the first url that the user attempted 
         * to request. By storing it and passing it to the login page, once 
         * authenticated, the user will be directed to the page they were initially
         * requesting. This is useful for when we link a user to a certain page from
         * an email or external source. 
         * 
         * This also creates some bad loops depending on which page the user is coming from so
         * in some cases (specifically authentication pages), we need to force the redirection
         */
        const [currentUrl, setCurrentUrl] = useState(['/signup', '/login', '/forgotPassword'].includes(window.location.pathname) ? '/dashboard' : window.location.pathname === '/logout' ? '/dashboard' : window.location.pathname);

        const { getSession, logout } = useContext(AccountContext);

        useEffect(() => {
                /**
                 * If the user is not authenticated, we will check their
                 * cognito session.
                 */
                if (!authenticated)
                {
                        getSession()
                                .then(session => { 
                                        setAuthenticated(true)
                                })
                                .catch(() => {
                                        logout()
                                        setAuthenticating(false)
                                })
                } else setAuthenticating(false)

        }, [authenticated, authenticating]);

        return (
            <BrowserRouter >
                <>
                    <Suspense fallback={<Loader />}>
                        <Routes>
                                {authenticated ?
                                        <Fragment>
                                                <Route path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />} /> 
                                                <Route path={`/login`} element={<Navigate to={`${process.env.PUBLIC_URL + currentUrl}`} />} /> 
                                                <Route path={`/*`} element={<LayoutRoutes />} /> 
                                        </Fragment>
                                                : <Route path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />} />}
                                <Route exact path={`${process.env.PUBLIC_URL}/login`} element={authenticating ? null : <LoginForm redirect_to={currentUrl} authenticating={false}/>} />
                                <Route exact path={`${process.env.PUBLIC_URL}/signup`} element={<SignUpForm />} />
                                <Route exact path={`${process.env.PUBLIC_URL}/forgotPassword`} element={<ForgotPasswordForm />} />
                                <Route path={`${process.env.PUBLIC_URL}/surveys`} element={<Survey />} />
                                <Route path={`/*`} element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />} />
                        </Routes>
                    </Suspense>
                </>
            </BrowserRouter>
        );
};

export default Routers;