import React, { Fragment, useState, useContext, useEffect } from 'react';
import TableBuilder, { ApiRequestBuilder } from '../../../GlobalComponents/Builders';
import { Container, Row, Card, CardBody } from 'reactstrap'
import moment from 'moment';

const AuditLog = () => {

    const [isProcessing, setIsProcessing] = useState(true);
    const [auditData, setAuditData] = useState([]);

    useEffect(() => {
    
        document.title = 'View Audit Log'

        ApiRequestBuilder('auditLogsGet', null, null, { setIsProcessing })
            .then(function(results) {
                results && results.data.length > 0 ? setAuditData(results.data) : []
            }
        )
    }, []);

    return (
            <Fragment>
                <Row>
                    <Container>
                        <Card>
                        <CardBody>
                            <TableBuilder
                                data={auditData}
                                title='Audit Log'
                                sortField={null}
                                subHeader={true}
                                processing={isProcessing}
                                columnsToSearch={['first_name', 'last_name', 'request_url']}
                                columns = {[
                                    {
                                        name: 'Name',
                                        selector: (row) => row.first_name + ' ' + row.last_name,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Page',
                                        selector: (row) => row.request_url,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Action',
                                        selector: (row) => row.action,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Time',
                                        selector: (row) => row.audit_time ? moment.unix(row.audit_time).format('LLL') : '-',
                                        sortable: true,
                                        center: true,
                                    }
                                ]}
                            />
                        </CardBody>
                        </Card>
                    </Container>
                </Row>
            </Fragment>
    );
};

export default AuditLog;