import { Navigate } from 'react-router-dom';

import Dashboard from '../Components/Dashboard';
import ProjectList from '../Components/Development';
import User from '../Components/Users/User';
import UsersList from '../Components/Users/List';
import { Logout } from '../GlobalComponents/Authentication'; 
import ToolsList from '../Components/Tools';
import PartnerList from '../Components/Partners';
import Partner from '../Components/Partners/Partner';
import ConnectionList from '../Components/Connections';
import Connection from '../Components/Connections/Connection';
import AuditLog from '../Components/Users/Audit';
import PsaSync from '../Components/Tools/PsaSync';
import SyncSettings from '../Components/Tools/PsaSync/Settings';
import Tool from '../Components/Tools/Tool';
import Jobs from '../Components/Tools/Tool/Jobs';
import Job from '../Components/Tools/Tool/Jobs/Job';
import ServiceList from '../Components/Services';
import Service from '../Components/Services/Service';
import HelpDashboard from '../Components/Help';
import Calendar from '../Components/Calendar';
import SubscriptionList from '../Components/Finance';
import Subpage from '../Components/Tools/Tool/Jobs/Job/Subpages';

export const routes = [

        { path: `${process.env.PUBLIC_URL}/calendar`, Component: <Calendar />, access: {global_access: 1, partner_access: 0, client_access: 0 }},
        
        { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Dashboard />, access: {global_access: 1, partner_access: 1, client_access: 1} },

        { path: `${process.env.PUBLIC_URL}/development`, Component: <ProjectList />, access: {global_access: 1, partner_access: 0, client_access: 0 }},

        { path: `${process.env.PUBLIC_URL}/partners`, Component: <PartnerList />, access: {global_access: 1, partner_access: 0, client_access: 0 }},
        { path: `${process.env.PUBLIC_URL}/partners/:partnerId`, Component: <Partner />, access: {global_access: 1, partner_access: 0, client_access: 0 }},

        { path: `${process.env.PUBLIC_URL}/services`, Component: <ServiceList />, access: {global_access: 1, partner_access: 0, client_access: 0 }},
        { path: `${process.env.PUBLIC_URL}/services/:serviceId`, Component: <Service />, access: {global_access: 1, partner_access: 0, client_access: 0 }},

        { path: `${process.env.PUBLIC_URL}/connections`, Component: <ConnectionList />, access: {global_access: 1, partner_access: 1, client_access: 0 }},
        { path: `${process.env.PUBLIC_URL}/connections/:connectionId`, Component: <Connection />, access: {global_access: 1, partner_access: 1, client_access: 0 }},

        { path: `${process.env.PUBLIC_URL}/finance/subscriptions`, Component: <SubscriptionList />, access: {global_access: 1, partner_access: 1, client_access: 1 }},

        { path: `${process.env.PUBLIC_URL}/tools`, Component: <ToolsList />, access: {global_access: 1, partner_access: 1, client_access: 0 }},
        { path: `${process.env.PUBLIC_URL}/tools/psa-sync`, Component: <PsaSync />, access: {global_access: 1, partner_access: 1, client_access: 0 }},
        { path: `${process.env.PUBLIC_URL}/tools/psa-sync/:syncId`, Component: <SyncSettings />, access: {global_access: 1, partner_access: 1, client_access: 0 }},

        { path: `${process.env.PUBLIC_URL}/tools/:toolId`, Component: <Tool />, access: {global_access: 1, partner_access: 1, client_access: 0 }},
        { path: `${process.env.PUBLIC_URL}/tools/:toolId/settings`, Component: <Tool />, access: {global_access: 1, partner_access: 0, client_access: 0 }},
        { path: `${process.env.PUBLIC_URL}/tools/:toolId/jobs`, Component: <Jobs />, access: {global_access: 1, partner_access: 1, client_access: 0 }},
        { path: `${process.env.PUBLIC_URL}/tools/:toolId/jobs/:jobId`, Component: <Job />, access: {global_access: 1, partner_access: 1, client_access: 0 }},
        { path: `${process.env.PUBLIC_URL}/tools/:toolId/jobs/:jobId/:subpage`, Component: <Subpage />, access: {global_access: 1, partner_access: 1, client_access: 0 }},
        { path: `${process.env.PUBLIC_URL}/tools/:toolId/jobs/:jobId/:subpage/:subpageId`, Component: <Subpage />, access: {global_access: 1, partner_access: 1, client_access: 0 }},

        { path: `${process.env.PUBLIC_URL}/users`, Component: <UsersList />, access: {global_access: 1, partner_access: 1, client_access: 1 }},
        { path: `${process.env.PUBLIC_URL}/users/:userId`, Component: <User />, access: {global_access: 1, partner_access: 1, client_access: 1 }},
        { path: `${process.env.PUBLIC_URL}/users/audit`, Component: <AuditLog />, access: {global_access: 1, partner_access: 1, client_access: 0 }},

        { path: `${process.env.PUBLIC_URL}/help`, Component: <HelpDashboard />, access: {global_access: 1, partner_access: 1, client_access: 0 }},

        { path: `${process.env.PUBLIC_URL}/logout`, Component: <Logout />, access: {global_access: 1, partner_access: 1, client_access: 1 }},

        // Safety redirect for unknown url paths
        { path: `${process.env.PUBLIC_URL}/*`, Component: <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />, access: {global_access: 1, partner_access: 1, client_access: 1 }}
];