import React, { Fragment, useEffect, useState, useRef, useContext } from 'react';
import { useParams, Link } from "react-router-dom";
import { Btn, H3, H4, P, Image } from "../../../AbstractElements";
import { useForm } from 'react-hook-form';
import { Row, Col, Container, Card, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input } from 'reactstrap'
import TableBuilder, { RegisteredInputsBuilder, ApiRequestBuilder, ToastBuilder, ModalButtonBuilder, FormOptionsBuilder, ButtonBuilder } from '../../../GlobalComponents/Builders';
import { setInitialFormValues, renameKeysSnakeToCamel, renameKeysCamelToSnake, buildOptionsFromArray } from '../../../GlobalComponents/Helpers';
import { AccountContext } from '../../../GlobalComponents/Authentication/Accounts';
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import moment from 'moment';

const Partner = () => {

    const { userSession, getSession } = useContext(AccountContext);

    const { partnerId } = useParams();

    const [partnerData, setPartnerData] = useState(null);
    const [toolData, setToolData] = useState([]);
    const [toolsToOmit, setToolsToOmit] = useState([])
    const [subscriptionData, setSubscriptionData] = useState([]);

    const emailsRef = useRef()

    const { register, handleSubmit, setValue, reset, getValues, formState: { errors } } = useForm();

    const handleSubscriptionCreate = (formData) => {
        const toolSelected = toolData.find(tool => tool.tool_id === parseInt(formData.toolToSubscribe))
        const payload = { admin: userSession.name, partnerId: partnerId, partnerName: partnerData.name, toolName: toolSelected.name, toolId: toolSelected.tool_id }
        ApiRequestBuilder('toolsToolIdSubscriptionsPost', { toolId: formData.toolToSubscribe }, { ...payload })
            .then(function (results) {
                getPartnerData()
            }).catch((err) => { })
    }

    const onProfileEdit = (profileInformation) => {
        const profileKeys = ['isActive', 'name', 'phoneNumber', 'domain', 'altDomains']
        for (let key in profileInformation) {
            if (!(profileKeys.includes(key))) {
                delete profileInformation[key]
            }
        }
        if (updatePartner(profileInformation)) {
            profileInformation = renameKeysCamelToSnake(profileInformation)
            ApiRequestBuilder('partnersPartnerIdPatch', { partnerId }, { ...profileInformation }).then(function (results) {
                ToastBuilder('success', 'Partner Profile Updated!')
                reset();
                getPartnerData();
            }).catch((err) => {
                ToastBuilder('error', 'Something went wrong')
            })
        } else {
            ToastBuilder('info', 'Nothing to update')
        }
    }

    const updatePartner = (formValues) => {
        const partner = renameKeysSnakeToCamel(partnerData)
        for (let input in formValues) {
            if (input in partner &&
                ((formValues[input] !== '' && formValues[input] !== partner[input])
                    || (formValues[input] === '' && formValues[input] !== partner[input])
                )
            ) {
                return true
            }
        }
        return false;
    }

    const getPartnerData = () => {
        ApiRequestBuilder('partnersPartnerIdGet', { partnerId })
            .then(function (results) {
                if (results.data) {
                    setInitialFormValues(results.data, setValue)
                    setPartnerData(results.data)
                    setToolsToOmit(results.data.subscriptions.filter(sub => sub.is_active === 1).map(element => element.tool_id))
                    setSubscriptionData(results.data.subscriptions.filter(sub => sub.is_active === 1))
                }
            }).catch((err) => { })
    }

    const getToolsData = () => {
        ApiRequestBuilder('toolsGet').then(function (results) {
            if (results.data) {
                const filteredTools = results.data.filter(element => !element.partner_id || element.partner_id === partnerId)
                setToolData(filteredTools)
            }
        }).catch((err) => { })
    }

    const sendEmail = (formData) => {
        if (!stringToListHandler(formData.userEmails)) {
            ToastBuilder('info', "No email recipients.")
        } else if (subscriptionData.length === 0) {
            ToastBuilder('info', "No active subscriptions.")
        }
        else {
            ApiRequestBuilder('partnersPartnerIdOnboardPost', { partnerId }, { ...formData, emails: emailsRef.current }, { toastOnSuccess: 'Emails sent.' }).then(function (results) {
            }).catch((err) => {
                ToastBuilder('success', 'Something went wrong.')
            })
        }
    }

    const stringToListHandler = (string) => {
        let set = new Set(string.replace(/\s/g, '').split(','));
        set.delete('')
        // This is where the email validation will be added for V1+
        emailsRef.current = [...set]
        if (set.size > 0) return true
        return false
    }

    useEffect(() => {
        document.title = 'View Partner'
        getToolsData()
        getPartnerData()
    }, []);

    return (
        <Fragment>
            {partnerData ?
                <div className="edit-profile">
                    <Row>
                        <Col xl="6">
                            <Form onSubmit={handleSubmit(onProfileEdit)}>
                                <Card>
                                    <CardBody>
                                        <Row className="mb-2">
                                            <div className="profile-title">
                                                <div className="media">
                                                    <div className="media-body">
                                                        {/* <H3 attrH3={{ className: 'mb-1 f-20 txt-primary' }}>{subscriptionData.name}</H3> */}
                                                        <H3>{partnerData.name && partnerData.name !== '' ? partnerData.name : 'PARTNER'}</H3>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                        <RegisteredInputsBuilder
                                            registrator={register}
                                            registratorErrors={errors}
                                            inputs={[
                                                {
                                                    label: 'Company Name',
                                                    placeholder: 'Company ABC',
                                                    size: 12,
                                                    name: 'name'
                                                },
                                                {
                                                    label: 'Phone Number',
                                                    placeholder: 'Please enter phone number',
                                                    size: 12,
                                                    name: 'phoneNumber'
                                                },
                                                {
                                                    label: 'Domain Name',
                                                    placeholder: 'company-site.com',
                                                    size: 12,
                                                    name: 'domain'
                                                },
                                                {
                                                    label: 'Alt Domains',
                                                    placeholder: 'company.org, company-domain.com',
                                                    size: 12,
                                                    name: 'altDomains'
                                                }
                                                // {
                                                //     label: 'Primary Contact',
                                                //     placeholder: 'Rick Fowler',
                                                //     size: 12,
                                                //     name: 'primaryContact'
                                                // }
                                            ]}
                                        />
                                    </CardBody>
                                    <CardFooter className="text-end">
                                        <Btn attrBtn={{ color: "primary", type: "submit" }} >{'Update Profile'}</Btn>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                        <Col xl="6">
                            <Card>
                                <CardBody>

                                    <H4>Admin</H4>
                                    <br />
                                    <ModalButtonBuilder title='Send Onboarding Email' className='btn-primary' size='lg'
                                        id='send-onboarding-email-form'
                                        body={
                                            <Form
                                                id='send-onboarding-email-form'
                                                onSubmit={handleSubmit(sendEmail)}
                                            >
                                                <RegisteredInputsBuilder
                                                    registrator={register}
                                                    registratorErrors={errors}
                                                    inputs={[
                                                        {
                                                            label: 'User Emails',
                                                            size: 12,
                                                            labelSize: 3,
                                                            type: 'text',
                                                            placeholder: 'portal_user@gmail.com, company@email.com',
                                                            name: 'userEmails',
                                                            helpText: 'Please enter Comma separated email addresses',
                                                            onKeyDown: (event) => { if (event.code === 'Enter') event.preventDefault() }
                                                        },
                                                    ]} />
                                            </Form>
                                        } />
                                    <ButtonBuilder label='Enter Partner Portal' className='m-1 m-l-20' onClick={() =>
                                        getSession().then(({ user }) => {
                                            const attributes = [
                                                new CognitoUserAttribute({ Name: "custom:partnerId", Value: String(partnerData.partner_id) }),
                                                new CognitoUserAttribute({ Name: "custom:emulationActive", Value: 'true' }),
                                            ];
                                            user.updateAttributes(attributes, (err, result) => {
                                                if (err) console.error(err);
                                                localStorage.setItem('partnerName', partnerData.name)
                                                window.location.href = process.env.PUBLIC_URL
                                                console.log(user);
                                            });
                                        })}
                                    />
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <TableBuilder
                                        subHeader={true}
                                        columnsToSearch={['name']}
                                        data={subscriptionData}
                                        processing={false}
                                        title='Subscriptions'
                                        actions={<ModalButtonBuilder title='Create Subscription' className='btn-primary'
                                            formId='create-subscription-form'
                                            body={
                                                <Fragment>
                                                    <Form id={'create-subscription-form'} onSubmit={handleSubmit(handleSubscriptionCreate)}>
                                                        <RegisteredInputsBuilder
                                                            registrator={register}
                                                            registratorErrors={errors}
                                                            inputs={[
                                                                {
                                                                    label: 'Tool',
                                                                    type: 'select',
                                                                    size: 12,
                                                                    labelSize: 3,
                                                                    inputSize: 7,
                                                                    required: false,
                                                                    options: <FormOptionsBuilder options={buildOptionsFromArray(toolData, { label: 'name', value: 'tool_id' }, toolsToOmit)} />,
                                                                    name: 'toolToSubscribe',
                                                                }
                                                            ]} />
                                                    </Form>
                                                </Fragment>
                                            }
                                        />}
                                        columns={[
                                            {
                                                name: 'Tool',
                                                selector: (row) => row.tool_name,
                                                sortable: true,
                                                center: true,
                                            },
                                            {
                                                name: 'Subscribed At',
                                                selector: (row) => moment(new Date(row.subscribed_at * 1000)).format('YYYY-MM-DD'),
                                                sortable: true,
                                                center: true,
                                            },
                                            {
                                                name: 'Cancel Subscription',
                                                selector: (row) => <ModalButtonBuilder title='Cancel' className='btn-danger m-1'
                                                    body={
                                                        <Form id='cancel-form' onSubmit={handleSubmit(() => {
                                                            ApiRequestBuilder('toolsToolIdSubscriptionsSubscriptionIdDelete', { toolId: row.tool_id, subscriptionId: row.subscription_id }, { partnerId })
                                                                .then(function (results) {
                                                                    getPartnerData()
                                                                }).catch((err) => { console.log('err', err) })
                                                        })
                                                        }>
                                                            <p>Are you sure you want to cancel this subscription?</p>
                                                            <br />
                                                        </Form>
                                                    }
                                                />,
                                                sortable: true,
                                                center: true,
                                            }
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div> : null}
            <Row>
            </Row>
        </Fragment>
    );
};
export default Partner;