import React, { Fragment, useState, useEffect, useContext } from 'react';
import TableBuilder, { ApiRequestBuilder, ButtonBuilder, ModalButtonBuilder, RegisteredInputsBuilder, ToastBuilder, FormOptionsBuilder, ModalLinkBuilder, TilesBuilder } from '../../../../GlobalComponents/Builders';
import { buildOptionsFromArray, convertTimestampToTimePicker } from '../../../../GlobalComponents/Helpers';
import { Container, Row, Col, Card, CardBody, Form, NavItem, NavLink, Nav, TabContent, TabPane } from 'reactstrap'
import { H4 } from '../../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { User, UserPlus, Calendar, Key, Unlock, Users } from 'react-feather';
import CountUp from 'react-countup';
import { AccountContext } from '../../../../GlobalComponents/Authentication/Accounts';
import moment from 'moment'


const GlobalSubscriptionList = () => {

    const { userSession } = useContext(AccountContext);

    const [basicTab, setBasicTab] = useState('1');
    const [subscriptionData, setSubscriptionData] = useState([])
    const [subscriptionViewData, setSubscriptionViewData] = useState(null)
    const [isProcessing, setIsProcessing] = useState(true);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const tabList = [
        {
            title: 'All',
            filter: null
        },
        {
            title: 'Active',
            filter: 'is_active'
        },
        {
            title: 'Unsubscribed',
            filter: 'not_active'
        }
    ]

    const isAdmin = () => {
        if (userSession['custom:emulationActive'] === 'true' || userSession['custom:partnerId'] === '0') return true
        return false
    }

    var tiles = [
        {
            id: 0,
            icon: 'Users',
            title: isAdmin() ? 'Total Subscriptions' : 'NexNow Tool Subscriptions',
            count: <CountUp end={subscriptionData.length} duration={3} />,
        },
        {
            id: 1,
            icon: 'User',
            title: 'Monthly Subscriptions',
            count: <CountUp end={subscriptionData.filter(sub => sub.is_active).length} duration={3} />,
            backgroundIcon: 'Calendar'
        },
        {
            id: 2,
            icon: 'UserPlus',
            title: 'Annual Subscriptions',
            count: <CountUp end={7} duration={3} />,
            backgroundIcon: 'Calendar'
        },
        {
            id: 3,
            icon: 'Key',
            title: 'Portal Subscriptions',
            count: <CountUp end={8} duration={3} />,
            backgroundIcon: 'Unlock'
        }
    ]


    const getSubscriptionData = () => {
        ApiRequestBuilder('subscriptionsGet', null, null, { setIsProcessing }).then(function (results) {
            if (results.data && results.data.length > 0) {

                if (userSession['custom:emulationActive'] === 'false' && parseInt(userSession['custom:partnerId']) === 0) {
                    setSubscriptionData(results.data)
                }
                else if (userSession['custom:emulationActive'] === 'false' && parseInt(userSession['custom:partnerId']) > 0) {
                    setSubscriptionData(results.data.filter(element => element.partner_id === parseInt(userSession['custom:partnerId']) && element.is_active))
                }
                else {
                    setSubscriptionData(results.data.filter(element => element.partner_id === parseInt(userSession['custom:partnerId'])))
                }
            }
        }
        )
    }

    const handleSubscriptionView = (subscriptionId) => {
        setSubscriptionViewData(subscriptionData.filter(element => element.subscription_id === subscriptionId)[0])
    }

    const handleUpdateSubscription = (formData, subscriptionId) => {
    }

    const secondsToDuration = (seconds) => {
        const years = Math.floor(seconds / (3600 * 24 * 365))
        seconds = seconds % (3600 * 24 * 365)
        const months = Math.floor(seconds / (3600 * 24 * 30))
        seconds = seconds % (3600 * 24 * 30)
        const days = Math.floor(seconds / (3600 * 24))
        seconds = seconds % (3600 * 24)
        const hours = Math.floor(seconds / 3600)
        seconds = seconds % 3600
        const minutes = Math.floor(seconds / 60)
        seconds = seconds % 60
        const y = years > 0 ? (years > 1 ? years + ' years ' : years + ' year ') : ''
        const m = months > 0 ? (months > 1 ? months + ' months ' : months + ' month ') : ''
        const d = days > 0 ? (days > 1 ? days + ' days ' : days + ' day ') : ''
        const h = hours > 0 ? hours + 'hr ' : ''
        const min = minutes > 0 ? minutes + 'm ' : ''
        return years > 0 ? '' + y + m : (months > 0 ? '' + m + d : (days > 0 ? '' + d + h : (hours > 0 ? '' + h + min : (minutes > 0 ? '' + min + seconds + 's' : '' + seconds + 's'))))
    }

    const handleUnsubscribe = (subscriptionId) => {
    }

    useEffect(() => {
        document.title = 'Partner Subscriptions'
        getSubscriptionData()
    }, []);


    return (
        <Fragment>
            <Container fluid={true}>
                    <Fragment>
                        <TilesBuilder data={tiles}/>
                    </Fragment>
            </Container>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            {
                                isAdmin() &&
                                <Nav tabs>
                                    {tabList.map((tab, i) =>
                                        <NavItem key={tab.title}>
                                            <NavLink href="#" className={basicTab === String(i) ? 'active' : ''} onClick={() => setBasicTab(String(i))}>{tab.title}</NavLink>
                                        </NavItem>
                                    )}
                                </Nav>
                            }
                            <TabContent activeTab={basicTab}>
                                {tabList.map((tab, i) =>
                                (
                                    <TabPane className="fade show" tabId={String(i)} key={tab.title + i}>

                                        <TableBuilder
                                            subHeader={true}
                                            columnsToSearch={['tool_name', 'partner_name']}
                                            data={subscriptionData.filter(sub => !tab.filter ? sub : tab.filter === 'is_active' ? sub.is_active === 1 : sub.is_active === 0)}
                                            processing={isProcessing}
                                            title='Subscriptions'
                                            columns={[
                                                {
                                                    name: 'ID',
                                                    selector: (row) => row.subscription_id,
                                                    sortable: true,
                                                    center: true,
                                                    maxWidth: '10%',
                                                },
                                                {
                                                    name: 'Tool',
                                                    selector: (row) => row.tool_name,
                                                    sortable: true,
                                                    center: true,
                                                },
                                                {
                                                    name: 'Partner',
                                                    selector: (row) => row.partner_name,
                                                    sortable: true,
                                                    center: true,
                                                },
                                                {
                                                    name: 'Type',
                                                    selector: (row) => 'Monthly',
                                                    sortable: true,
                                                    center: true,
                                                    omit: isAdmin() ? true : false,
                                                    maxWidth: '18%'
                                                },
                                                {
                                                    name: 'Status',
                                                    selector: (row) => row.is_active ? <p style={{ color: 'green' }}>Active</p> : 'Unsubscribed',
                                                    sortable: true,
                                                    center: true,
                                                    maxWidth: '15%',
                                                },
                                                {
                                                    name: 'Subscriptions',
                                                    selector: (row) =>
                                                        <ModalButtonBuilder title='View Details' className='btn-primary btn-sm m-1' onClick={() => handleSubscriptionView(row.subscription_id)}
                                                            onSubmit={row.is_active ? true : false}
                                                            onCancelLabel={row.is_active ? 'Cancel' : 'Close'}
                                                            onDelete={row.is_active ? () => { handleUnsubscribe(row.subscription_id) } : null}
                                                            onDeleteLabel='Unsubscribe'
                                                            formId='update-subscription-form'
                                                            body={
                                                                <CardBody className='typography p-10 p-l-15'>
                                                                    {subscriptionViewData && (
                                                                        <>
                                                                            {subscriptionViewData.tool_name ? (<h5 className='txt-success'>{subscriptionViewData.tool_name}</h5>) : ''}
                                                                            {subscriptionViewData.subscription_id ? (<p><strong>Subscription Id: </strong>{' ' + subscriptionViewData.subscription_id}</p>) : ''}
                                                                            {subscriptionViewData.partner_name ? (<p><strong>Partner: </strong>{' ' + subscriptionViewData.partner_name}</p>) : ''}
                                                                            {subscriptionViewData.subscribed_at ? (<p><strong>Start Service: </strong>{' ' + moment(new Date(subscriptionViewData.subscribed_at * 1000)).format('MM-DD-YYYY')}</p>) : ''}
                                                                            {subscriptionViewData.unsubscribed_at ? (<p><strong>End Service: </strong>{' ' + moment(new Date(subscriptionViewData.unsubscribed_at * 1000)).format('MM-DD-YYYY')}</p>) : ''}
                                                                            { subscriptionViewData.subscribed_at && subscriptionViewData.unsubscribed_at ? (<p><strong>Duration: </strong>{' ' + secondsToDuration(subscriptionViewData.unsubscribed_at - subscriptionViewData.subscribed_at)}</p>) : ''}
                                                                            {'is_active' in subscriptionViewData ?
                                                                                (subscriptionViewData.is_active === 0 ? (<p><strong>Status: </strong><span style={{ color: 'red' }}> Unsubscribed</span></p>) : (<p><strong>Status: </strong><span style={{ color: 'green' }}> Active</span></p>)) : ''}
                                                                            {<p><strong>Subscription:</strong> Per use/Monthly/Annual/Portal</p>}
                                                                        </>
                                                                    )}
                                                                    <br />
                                                                    <Form id='update-subscription-form' onSubmit={handleSubmit((formData) => handleUpdateSubscription(formData, row.subscription_id))}>
                                                                        <RegisteredInputsBuilder
                                                                            registrator={register}
                                                                            registratorErrors={errors}
                                                                            inputs={row.is_active ? [
                                                                                {
                                                                                    label: 'Subscription',
                                                                                    size: 11,
                                                                                    labelSize: 5,
                                                                                    inputSize: 6,
                                                                                    type: 'select',
                                                                                    required: false,
                                                                                    options: <FormOptionsBuilder options={buildOptionsFromArray([{ id: 0, value: 'Per use' }, { id: 1, value: 'Monthly' }, { id: 2, value: 'Annual' }, { id: 3, value: 'Portal' }], { label: 'value', value: 'is_active' })} />,
                                                                                    name: 'subscriptionType',
                                                                                }
                                                                            ] : []} />
                                                                    </Form>

                                                                </CardBody>
                                                            }
                                                        />
                                                    ,
                                                    sortable: true,
                                                    center: true,
                                                },
                                            ]}
                                        />

                                    </TabPane>
                                )
                                )}
                            </TabContent>

                        </CardBody>
                    </Card>
                </Container>
            </Row>
        </Fragment>
    );
};

export default GlobalSubscriptionList;