import React, { Fragment, useEffect, useState } from 'react';
import { set, useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
import { Row, Col, Container, Card, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input } from 'reactstrap'
import TableBuilder, { ApiRequestBuilder, ButtonBuilder, RegisteredInputsBuilder } from '../../GlobalComponents/Builders';

const Survey = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    
    var surveyId = searchParams.get('request_id')

    const [surveyData, setSurveyData] = useState(null);
    const [notification, setNotification] = useState(null);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const buildNotificationBanner = (code) => {

        var notificationTextSettings = {
            'backorder-review' : {
                'alreadySubmitted' : <p>This survey has been completed or is expired, you will be notified separately for any items still on backorder.<br/><br/>Thank you.</p>,
            }
        }

        switch(code)
        {
            case 'pastDue':
            case 'alreadySubmitted':
                setNotification(notificationTextSettings[surveyData.survey_key] && notificationTextSettings[surveyData.survey_key].alreadySubmitted ? notificationTextSettings[surveyData.survey_key].alreadySubmitted : <p>This survey has been completed or is expired.<br/><br/>Thank you.</p> )
                break;
            case 'missingRequest':
                setNotification(<p>Our sincerest apologies, we are unable to find your order.<br/><br/>Please call your support team if adjustments need to be made.<br/><br/>Thank you.</p>)
                break;
            case 'error':
                setNotification(<p>Our sincerest apologies, we are unable to make the requested changes.<br/><br/>Please call your support team if adjustments need to be made.<br/><br/>Thank you.</p>)
                break;
            case 'submitted':
                setNotification(<p>Thank you for your submission!<br/><br/>We'll review your submission and get back to you as soon as possible.</p>)
                break;
        }
    }

    const onFormSubmission = (entry) => {

        switch(surveyData.survey_key)
        {
            case 'backorder-review':

                var purchaseOrderResponses = []
                                                        
                surveyData.content.map(purchaseOrder => {
                
                    var productResponses = []
                    var orderReferenceId = surveyData.order_number_reference === 'work_order_number' ? purchaseOrder.work_order_number.replace('O', 'Q') : purchaseOrder.purchase_order_id
        
                    purchaseOrder.purchase_order.products.forEach(product => {
        
                        productResponses.push({
                            productId : product.IV_Item_RecID,
                            response: entry['talk-more-' + orderReferenceId + '-' + product.IV_Item_RecID],
                            comments: entry['comments-' + orderReferenceId + '-' + product.IV_Item_RecID],
                        })
                    })
                    
                    purchaseOrderResponses.push({
                        purchaseOrderId: purchaseOrder.purchase_order.id,
                        workOrderNumber: purchaseOrder.work_order_number,
                        productResponses : productResponses
                    })
                })
        
                ApiRequestBuilder('surveySurveyIdRequestRequestIdResponsePost', { surveyId: surveyData.survey_id, requestId: surveyId}, { 
                    partnerId : surveyData.partner_id,
                    purchaseOrderResponses : purchaseOrderResponses
                }).then(function() {
                    buildNotificationBanner('submitted')
                }).catch(function() {
                    buildNotificationBanner('error')
                })

                break;

            case 'dropship-review':

                var itemResponses = []
                
                surveyData.content.line_items.forEach(lineItem => {
                    
                    itemResponses.push({
                         missing: entry['missing-item-' + lineItem.id],
                         assistanceNeeded: entry['need-installation-assistance-' + lineItem.id],
                         lineItem: lineItem,
                         contact: surveyData.recipients.to
                    })
                })

                ApiRequestBuilder('surveySurveyIdRequestRequestIdResponsePost', { surveyId: surveyData.survey_id, requestId: surveyId}, { 
                    partnerId : surveyData.partner_id,
                    //companyId : lineItems[0]['company']['id'], TO-DO: Do you need to store the company that received this as well?
                    contactId : surveyData.content.line_items[0]['contact_id'],
                    itemResponses : itemResponses
                }).then(function(){
                    buildNotificationBanner('submitted')
                }).catch(function(){
                    buildNotificationBanner('error')
                });
        }
    }

    const buildDropshipList = () => {
        
        return (
            <TableBuilder
                data={surveyData.content.line_items}
                processing={false}
                title = ''
                columns = {[
                    {
                        name: 'Name',
                        selector: (row) => {
                            if ( 'description' in row){
                                return row.description.length > 80 ? row.description.substring(0,80) + '...' : row.description
                            }
                            else {
                                return '-'
                            }
                        },
                        sortable: true,
                        center: true,
                        maxWidth: '30%'
                    },
                    {
                        name: 'Quantity',
                        selector: (row) => 'quantity' in row ? row.quantity : '-',
                        sortable: true,
                        center: true,
                        maxWidth: '8%'
                    },
                    {
                        name: 'Date Received',
                        selector: (row) => 'dateReceived' in row ? row.dateReceived.split('T')[0] : '-',
                        sortable: true,
                        center: true,
                        maxWidth: '15%'
                    },
                    {
                        name: 'Tracking',
                        selector: (row) => 'trackingNumber' in row ? row.trackingNumber : '-',
                        sortable: true,
                        center: true,
                        maxWidth: '15%'
                    },
                    {
                        name: 'Missing This Item?',
                        selector: (row) => <RegisteredInputsBuilder 
                            registrator={register}
                            registratorErrors={errors}
                            inputs={[
                                {
                                    label: '',
                                    inputSize: 3,
                                    name: 'missing-item-' + String(row.id),
                                    type: 'checkbox',
                                    style: { backgroundColor: 'gray' }
                                }
                            ]}
                        />,
                        sortable: true, 
                        center: true,
                        maxWidth: '30%'
                    },
                    {
                        name: 'Need Installation Assitance?',
                        selector: (row) => <RegisteredInputsBuilder 
                            registrator={register}
                            registratorErrors={errors}
                            inputs={[
                                {
                                    label: '',
                                    inputSize: 3,
                                    name: 'need-installation-assistance-' + String(row.id),
                                    type: 'checkbox',
                                    style: { backgroundColor: 'gray' }
                                }
                            ]}
                        />,
                        sortable: true, 
                        center: true,
                        maxWidth: '30%'
                    }
                ]}
            />
        )
    }

    const buildPurchaseOrderList = () => {

        return surveyData.content.map(purchaseOrder => {
										
            var orderReferenceText = surveyData.order_number_reference === 'work_order_number' ? 'Quote' : 'Purchase Order'
            var orderReferenceId = surveyData.order_number_reference === 'work_order_number' ? purchaseOrder.work_order_number.replace('O', 'Q') : purchaseOrder.purchase_order_id
            
            // Attempt to remove the WO number from the opportunity name with a regex replacement
            //orderOpportunityName = purchaseOrder.opportunity.name.replace(new RegExp('\\(\\s?#\\s?' + orderReferenceId + '\\s?\\)', 'g'), '')
            
            // Remove anything inside parenthesis from the name since the format of the WO is not standardized
            var orderOpportunityName = purchaseOrder.opportunity.name.replace(new RegExp('\\(.+\\)', 'g'), '')

            return (
                
                <TableBuilder
                    data={purchaseOrder.purchase_order.products}
                    processing={false}
                    title = {orderOpportunityName + ' - ' + orderReferenceText + " #: " + orderReferenceId}
                    columns = {[
                        {
                            name: 'Product Description',
                            selector: (row) => row.Description.slice(0, 50) + '...',
                            sortable: true,
                            center: true,
                        },
                        {
                            name: 'Expected Ship Date',
                            selector: (row) => row.Expected_Ship_Date,
                            sortable: true,
                            center: true,
                            maxWidth: '15%'
                        },
                        {
                            name: 'Talk More',
                            selector: (row) => <RegisteredInputsBuilder 
                                registrator={register}
                                registratorErrors={errors}
                                inputs={[
                                    {
                                        label: '',
                                        inputSize: 3,
                                        name: 'talk-more-' + orderReferenceId + '-' + String(row.IV_Item_RecID),
                                        type: 'checkbox',
                                        style: {backgroundColor: 'gray'}
                                    }
                                ]}
                            />,
                            sortable: true, 
                            center: true,
                            maxWidth: '9%'
                        },
                        {
                            name: 'Comments',
                            selector: (row) => <RegisteredInputsBuilder 
                                registrator={register}
                                registratorErrors={errors}
                                inputs={[
                                    {
                                        label: '',
                                        size: 12,
                                        inputSize: 12,
                                        name: 'comments-' + orderReferenceId + '-' + String(row.IV_Item_RecID),
                                        type: 'text'
                                    }
                                ]}
                            />,
                            sortable: true,
                            center: true,
                            width: '30%'
                        }
                    ]}
                />
            )
        })
    }

    const buildSurvey = () => {

        switch(surveyData.survey_key)
        {
            case 'backorder-review':
                return buildPurchaseOrderList()
            case 'dropship-review':
                return buildDropshipList()
        }
    }

    const buildSurveyHeader = () => {

        switch(surveyData.survey_key)
        {
            case 'backorder-review':
                return ( 
                    <Fragment>
                        {surveyData.email_header_logo_url ? <img style={{marginBottom: 20}} src={surveyData.email_header_logo_url} /> : null}
                        <h1>Items on Backorder</h1>
                        <p>If you'd like to discuss potential alternatives or get more details on the order, please fill out the form below. We'll reach out!</p>
                    </Fragment>
                )
            case 'dropship-review':
                return ( 
                    <Fragment>
                        {surveyData.email_header_logo_url ? <img style={{marginBottom: 20}} src={surveyData.email_header_logo_url} /> : null}
                        <h1>Drop Ship Items</h1>
                    </Fragment>
                )
        }
    }

    useEffect(() => {

        document.title = 'View Survey'

        if (surveyId)
        {
            ApiRequestBuilder('surveySurveyIdRequestRequestIdGet', { 
                surveyId : 1, // Arbitrary survey id, it isn't used downstream
                requestId : surveyId 
            }).then(function(results) {

                if (results.data)
                {
                    
                    var pastDueNotification = !(new Date(Number(results.data.sent_at * 1000)) > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
                    var missingRequestNotification = !results.data || results.data.response.length === 0
                    var alreadySubmittedNotification =  results.data.response !== 'None'

                    if ((!pastDueNotification && !missingRequestNotification && !alreadySubmittedNotification) || localStorage.getItem('accessLevel').includes('partner') || localStorage.getItem('accessLevel').includes('global'))
                    {
                        setSurveyData(results.data)
                    }
                    else
                    {
                        if (pastDueNotification || alreadySubmittedNotification)
                        {
                            buildNotificationBanner('pastDue')
                        } 
                        else if (missingRequestNotification)
                        {
                            buildNotificationBanner('missingRequest')
                        }
                    }
                }
                else
                {
                    buildNotificationBanner('error')
                }
            })
        }
 
    }, []);

    return (
        <Fragment>
            <center>
                <Container fluid={true} className="m-4">
                <Col sm="11">
                    <Card>
                        <CardBody>
                            { notification ? notification : 
                            <Fragment>
                                <CardHeader>
                                    { surveyData ?
                                        <Fragment>
                                            {surveyData ? buildSurveyHeader() : null }
                                        </Fragment> 
                                    : 
                                    <img style={{marginTop: 75, marginBottom: 100, height:75}} src="https://cdn.itrucksack.com/images/loading-icon.gif"/>
                                    }
                                </CardHeader>
                                <Form onSubmit={handleSubmit(onFormSubmission)}>
                                    <CardBody>
                                            { surveyData ? buildSurvey() : null}
                                    </CardBody>
                                    <CardFooter className="text-end">
                                        <center><ButtonBuilder label='Submit Form' type='submit'/></center>
                                    </CardFooter>
                                </Form>
                            </Fragment>}
                        </CardBody>
                    </Card>
                </Col>
                </Container>
            </center>
        </Fragment>
    );
};
export default Survey;