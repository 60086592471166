import React, { Fragment, useState, useEffect } from 'react';
import { ApiRequestBuilder, FormOptionsBuilder, ToastBuilder, RegisteredInputsBuilder, ButtonBuilder } from '../../../../../../GlobalComponents/Builders';
import { buildOptionsFromArray, setInitialFormValues } from '../../../../../../GlobalComponents/Helpers';
import { Row, Card, CardBody, Form, Modal, Col, CardFooter } from 'reactstrap'
import { useForm } from 'react-hook-form';

const TimeEntryLocationAdjusterJob = ({jobData, connectionData, jobSettings}) => {
    
    const [isProcessing, setIsProcessing] = useState(true);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const handleJobStart = () => {
        
        ApiRequestBuilder('toolsToolIdJobsJobIdStartPost', { toolId, jobId }, {empty: 'body'}).then(function(results){
            ToastBuilder('success', 'Job Started')
        })
    }

    useEffect(() => {

        var intialValues = {}
        
        jobSettings.map(setting => {
            intialValues[setting.name] = setting.value
        }); 

        setInitialFormValues(intialValues, setValue)

    }, []);

    return (
        <Fragment>
            <Row>
                <Col xl="6">
                    <Form onSubmit={handleSubmit(() => {})}>
                        <Card>
                            <CardBody>
                                <h4>Job Settings</h4>
                                <br/>
                                <RegisteredInputsBuilder 
                                    registrator={register}
                                    registratorErrors={errors}
                                    inputs={[
                                        {
                                            label: 'Destination Connection',
                                            type: 'static',
                                            size: 12,
                                            inputSize: 6,
                                            name: 'destinationConnection',
                                            value: <FormOptionsBuilder options={buildOptionsFromArray(connectionData, { label: 'name', value: 'item_id'})}/>,
                                        },
                                        {
                                            label: 'Frequency',
                                            type: 'select',
                                            size: 12,
                                            inputSize: 6,
                                            name: 'frequency',
                                            options: <FormOptionsBuilder options={buildOptionsFromArray(connectionData, { label: 'name', value: 'item_id'})}/>,
                                        },
                                        {
                                            label: 'Locations',
                                            type: 'select',
                                            size: 12,
                                            inputSize: 6,
                                            name: 'locations',
                                            options: <FormOptionsBuilder options={buildOptionsFromArray(connectionData, { label: 'name', value: 'item_id'})}/>,
                                        }
                                    ].concat(
                                        jobSettings.map(setting => {

                                            return {
                                                label: setting.label,
                                                size: 12,
                                                inputSize: 6,
                                                name: setting.name,
                                                type: setting.datatype,
                                                helpText: setting.helpText,
                                                options: setting.options ? <FormOptionsBuilder options={buildOptionsFromArray(setting.options, { label: 'label', value: 'id'})}/> : null
                                            }
                                        }
                                    )
                                    )}
                                />
                            </CardBody>
                            <CardFooter className="text-end">
                                <ButtonBuilder label='Update Connection'/>
                            </CardFooter>
                        </Card>
                    </Form>
                </Col>
                <Col xl="6">
                    <Card>
                        <CardBody>
                            <h4>Job Actions</h4>
                            <br/>
                            <p>Currently Disabled</p>
                            <br/>
                            <p>Last Run: 04-05-23</p>
                            <br/>
                            <ButtonBuilder label='Enable Job' onClick={handleJobStart}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
};

export default TimeEntryLocationAdjusterJob;