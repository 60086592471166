import { Home, Users, Clipboard, HelpCircle, Zap, Briefcase, GitBranch, Link2, Calendar, TrendingUp } from 'react-feather';

export const buildMenuItems = () => {

    let Items = [
        {
            title: 'Calendar', icon: Calendar, type: 'link', active: false,  path: `${process.env.PUBLIC_URL}/calendar`, access: { global_access: 1, partner_access: 0, client_access: 0 } 
        },
        {
            title: 'Partners', icon: Briefcase, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/partners`, access: { global_access: 1, partner_access: 0, client_access: 0 } 
        },
        {
            title: 'Development', icon: GitBranch, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/development`, access: { global_access: 1, partner_access: 0, client_access: 0 } 
        },
        {
            title: 'Connections', icon: Link2, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/connections`, access: { global_access: 0, partner_access: 1, client_access: 0 } 
        },
        {
            title: 'Finance', icon: TrendingUp, type: 'sub', active: false, children: [
             { path: `${process.env.PUBLIC_URL}/finance/subscriptions`, type: 'link', title: 'Subscriptions', access: { global_access: 1, partner_access: 1, client_access: 1 } },
            ], access: { global_access: 1, partner_access: 1, client_access: 1 }
        },
        {
            title: 'Services', icon: Clipboard, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/services`, access: { global_access: 1, partner_access: 0, client_access: 0 } 
        },
        {
            title: 'Tools', icon: Zap, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/tools`, access: { global_access: 1, partner_access: 1, client_access: 0 } 
        },
        {
            title: 'Users', icon: Users, type: 'sub', active: false, children: [
                { path: `${process.env.PUBLIC_URL}/users`, type: 'link', title: 'List Users', access: { global_access: 1, partner_access: 1, client_access: 0 }  },
                { path: `${process.env.PUBLIC_URL}/users/audit`, type: 'link', title: 'View Audit Log', access: { global_access: 1, partner_access: 1, client_access: 0 }  },
            ], access: { global_access: 1, partner_access: 1, client_access: 0 }
        }
    ]

    /**
     * Sort items alphabetically before adding the top
     * level items
     */
    Items.sort((a,b) =>  a.title > b.title ? 1 : -1 )

    /**
     * For any menu items we want on top, we'll put them
     * at the start of the items array
     */
    Items.unshift(
        {
            title: 'Dashboard', icon: Home, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/dashboard`, access: { global_access: 1, partner_access: 1, client_access: 1 }
        }
    )

    return [
        {
            menutitle: '',
            menucontent: 'Dashboards,Widgets',
            Items: Items
        },
        {
            menutitle: '',
            menucontent: 'Dashboards,Widgets',
            Items: [
                {
                    title: 'Need Help?', icon: HelpCircle, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/help`, access: { global_access: 1, partner_access: 1, client_access: 1 }
                }
            ]
        }
    ]
}