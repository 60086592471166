import React, { Fragment, useState, useEffect, useContext } from 'react';
import TableBuilder, { ApiRequestBuilder, FormOptionsBuilder, RegisteredInputsBuilder, ModalButtonBuilder, ToastBuilder, TextTitleBuilder, ModalBuilder, ButtonBuilder } from '../../../../../../GlobalComponents/Builders';
import { Row, Card, CardBody, Form, Container } from 'reactstrap'
import { useForm } from 'react-hook-form';
import { Col } from 'react-bootstrap';
import { AccountContext } from '../../../../../../GlobalComponents/Authentication/Accounts';
import { Link, Navigate, useParams } from 'react-router-dom';
import { buildOptionsFromArray, renameKeysCamelToSnake } from '../../../../../../GlobalComponents/Helpers';

const OrderEntryPortalJob = ({jobData}) => {

    let { jobId } = useParams();
    
    const { userSession } = useContext(AccountContext);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [renderPage, setRenderPage] = useState(false);  
    const [orders, setOrders] = useState([]);  
    const [orderTypes, setOrderTypes] = useState([]);  

    const handleNewOrderCreate = (orderData) => {
        
        ApiRequestBuilder('oepOrdersPost', {}, renameKeysCamelToSnake(orderData) )
            .then(function(results) {
                if (results.data)
                {
                    ToastBuilder('success', 'Order Creation Successful')
                    window.location.href = process.env.PUBLIC_URL + '/tools/order-entry-portal/jobs/' + String(jobId) + '/orders/' + String(results.data.order_id)
                }
                else ToastBuilder('error', 'Unable to Create Order')
            }
        )
    }

    const getOrders = () => {

        ApiRequestBuilder('oepOrdersGet')
            .then(function(results) {

                setOrders(results.data.length > 0 ? results.data : [])

                ApiRequestBuilder('oepOrdersTypesGet')
                    .then(function(results) {
                        setOrderTypes(results.data.length > 0 ? results.data : [])
                        setRenderPage(true)
                    }
                )
            }
        )
    }

    var orderForm = [
        {
            label: 'Order #', 
            name: 'orderNumber',
            labelSize: 3,
            inputSize: 6,
            size: 12,
            required: true,
            type: 'number'
        },
        {
            label: 'Order Type', 
            name: 'orderType',
            type: 'select',
            labelSize: 3,
            inputSize: 6,
            size: 12,
            options: <FormOptionsBuilder options={buildOptionsFromArray(orderTypes, { label: 'name', value: 'type_id' })}/>
        },
        {
            label: 'Company Name',
            name: 'companyName',
            labelSize: 3,
            inputSize: 6,
            size: 12,
        },
        {
            label: 'Carrier',
            type: 'select',
            labelSize: 3,
            inputSize: 6,
            size: 12,
            required: false,
            options: <FormOptionsBuilder options={buildOptionsFromArray(['AT&T', 'T-Mobile', 'Verizon'], { labelIsValue: true })}/>,
            name: 'carrier'
        },
        {
            label: 'Passcode',
            name: 'passcode',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Account #',
            name: 'accountNumber',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Sim Provided By?',
            type: 'select',
            labelSize: 3,
            inputSize: 6,
            size: 12,
            required: false,
            options: <FormOptionsBuilder options={buildOptionsFromArray(['Carrier', 'Wireless Support'], { labelIsValue: true })}/>,
            name: 'simProvidedBy'
        },
        {
            label: 'Tax Id',
            name: 'taxId',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Spoc',
            name: 'spoc',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Associated Name',
            name: 'associatedName',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Spoc email',
            name: 'spocEmail',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Associated Email',
            name: 'associatedEmail',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Order By',
            name: 'orderBy',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Shipping Address',
            name: 'shippingAddress',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Shipping Option',
            name: 'shippingOption',
            type: 'select',
            labelSize: 3,
            inputSize: 6,
            size: 12,
            required: false,
            options: <FormOptionsBuilder options={buildOptionsFromArray(['2 Day', 'Overnight (Extra Charge)'], { labelIsValue: true })}/>,
        },
        {
            label: 'Order Emails',
            name: 'orderEmails',
            type: 'textarea',
            helpText: 'Separate emails by (,) comma.',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Order Instructions',
            name: 'orderInstructions',
            type: 'textarea',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Promotion Description',
            name: 'promotionDescription',
            type: 'textarea',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Is Account Clean-Up Needed?',
            name: 'cleanupNeeded',
            type: 'checkbox',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Account Cleanup Details',
            name: 'accountCleanupDetails',
            type: 'textarea',
            labelSize: 3,
            inputSize: 6,
            size: 12,
            helpText: 'If applicable'
        }
    ]

    useEffect(() => {   

        document.title = 'View Job'

        if (userSession['custom:partnerId'] !== String(jobData.partner_id) && userSession['custom:partnerId'] !== '0')
        {
            ToastBuilder('error', 'You don\'t have access to view this page')
        }
        else
        {
            getOrders()
        }

    }, []);

    if (renderPage)
    { 
        return (
            <Fragment>
                <Row>
                    <Col xl="6">
                        <Card>
                            <CardBody>
                                <h4>Job Settings</h4>
                                <br/>
                                {jobData && jobData.destination_connection ? (<p><strong>Target Connection: </strong>{' ' + jobData.destination_connection.name}</p>) : ''}                         
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl="6">
                        <Card>
                            <CardBody>
                                <h4>Actions</h4>
                                <br/>
                                <ButtonBuilder label='Check for New Sales Orders' className='m-2 col-md-5' onClick={() => {
                                    ApiRequestBuilder('jobsJobIdActionsActionNamePost', { jobId: jobId, actionName: 'import-new-orders' })
                                        .then(function(results) {
                                            ToastBuilder('success', 'Fetching New Sales Orders')
                                            getOrders()
                                        }
                                    )
                                }}/>
                                <ButtonBuilder label='Generate Labels' className='m-2 col-md-3 btn-secondary' onClick={() => {
                                    ApiRequestBuilder('jobsJobIdActionsActionNamePost', { jobId: jobId, actionName: 'generate-shipping-labels' })
                                        .then(function(results) {
                                            ToastBuilder('success', 'Generating Shipping Labels')
                                        }
                                    )
                                }}/>

                                {/*orderTypes.map(type => 
                                    <ModalButtonBuilder title={type.name} formId='create-order-form' className='m-2' size='lg' key={type.name}
                                        onClick={() => setValue('orderType', 1)}
                                        body={
                                            <Form id='create-order-form' onSubmit={handleSubmit((data) => handleNewOrderCreate(data))}>
                                                <RegisteredInputsBuilder
                                                    registrator={register}
                                                    registratorErrors={errors}
                                                    inputs={orderForm}
                                                />
                                            </Form>
                                        }
                                    />
                                    ) 
                                */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <TableBuilder
                                data = {orders}
                                processing = {false}
                                title = 'Orders'
                                columns = {[
                                    {
                                        name: 'Order #',
                                        selector: (row) => row.order_number,
                                        sortable: true,
                                        center: true,
                                        maxWidth: '8%'
                                    },
                                    {
                                        name: 'Type',
                                        selector: (row) => row.order_type_name,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Company',
                                        selector: (row) => row.company_name,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Closed',
                                        selector: (row) => row.is_closed ? <span style={{ color:'red'}}>Closed</span> : 'Open',
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Manage',
                                        selector: (row) => <Link to={`${process.env.PUBLIC_URL}/tools/order-entry-portal/jobs/` + String(jobId) + '/orders/' + String(row.order_id)}>View / Edit</Link>, 
                                        sortable: false,
                                        center: true,
                                        maxWidth: '12%'
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
            </Fragment>
        )
    }
    else 
    {
        if (userSession['custom:partnerId'] !== String(jobData.partner_id) && userSession['custom:partnerId'] !== '0')
        {
            return <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />
        }
        return <p>Loading Job Information...</p>
    }
};

export default OrderEntryPortalJob;