import React, { Fragment, useState, useEffect, useContext } from 'react';
import TableBuilder, { ApiRequestBuilder, ButtonBuilder, ModalButtonBuilder, RegisteredInputsBuilder, ToastBuilder } from '../../GlobalComponents/Builders';
import { Container, Row, Card, CardBody, Form } from 'reactstrap'
import { AccountContext } from '../../GlobalComponents/Authentication/Accounts';
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

const ServiceList = () => {

    const { getSession, userSession } = useContext(AccountContext);

    const [serviceData, setServiceData] = useState([]);
    const [isProcessing, setIsProcessing] = useState(true);
    
    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleServiceCreate = (newServiceData) => {
        
        ApiRequestBuilder('servicesPost', null, newServiceData).then(function(results){
            ToastBuilder('success', 'Service Creation Successful')

            if (results.data.id)
            {
                window.location.href = process.env.PUBLIC_URL + '/services/' + String(results.data.id)
            }
            else 
            {
                ToastBuilder('error', 'Unable to Create Service')
            }
            
        }).catch((err) => {
            console.log(err);
            ToastBuilder('error', 'Unable to Create Service')
        })
    }

    const getServices = () => {
        ApiRequestBuilder('servicesGet', null, null, { setIsProcessing })
            .then(function(results) {
                setServiceData(results.data && results.data.length > 0 ? results.data : [])
            }
        )
    }

    useEffect(() => {
    
        document.title = 'View Services'

        getServices()
    }, []);

    return (
            <Fragment>
                <Row>
                    <Container>
                        <Card>
                        <CardBody>
                            <TableBuilder
                                subHeader={true}
                                columnsToSearch={['name']}
                                paginationPerPage={15}
                                data={serviceData}
                                processing={isProcessing}
                                title='Services'
                                columns = {[
                                    {
                                        name: 'ID',
                                        selector: (row) => row.id,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Name',
                                        selector: (row) => row.name,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Manage',
                                        selector: (row) => <Link to={`${process.env.PUBLIC_URL}/services/` + row.id}>View / Edit</Link>,
                                        sortable: true,
                                        center: true,
                                    },
                                ]}
                            />
                        </CardBody>
                        </Card>
                    </Container>
                </Row>
            </Fragment>
    );
};

export default ServiceList;