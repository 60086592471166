import React, { Fragment, useState, useEffect, useLayoutEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { ApiRequestBuilder, ToastBuilder } from '../../GlobalComponents/Builders';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';

const localizer = momentLocalizer(moment);
let allViews = Object.keys(Views).map(k => Views[k]);

const CalenderContain = () => {
    
    document.title = 'View Calendar'
    
    const [calendarData, setCalendarData] = useState([]);
    const [fetchingCalendarData, setfetchingCalendarData] = useState(true);

    useEffect(() => {

        ApiRequestBuilder('developmentCalendarGet')
            .then(function(results) {
              setfetchingCalendarData(false)
              setCalendarData(results.data.map((calendarEvent) => {
                  calendarEvent.start = new Date(calendarEvent.start)
                  calendarEvent.end = new Date(calendarEvent.end)
                  return calendarEvent
              }))

            }
        ).catch(err => {
                setfetchingCalendarData(false)
                ToastBuilder('error', 'Failed to Get Calendar Events')
            }
        )
    }, []);
    
    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                { fetchingCalendarData ? <center><h4>Fetching Calendar Events...</h4></center> :
                                    <Calendar
                                        localizer={localizer}
                                        scrollToTime={new Date(1970, 1, 1, 6)}
                                        defaultDate={new Date()}
                                        onSelectEvent={event => alert(event.title + '\n' + moment.unix(event.start / 1000).format('MMMM Do YYYY, h:mm:ss a') + ' - ' +  moment.unix(event.end / 1000).format('MMMM Do YYYY, h:mm:ss a'))}
                                        views={allViews}
                                        events={calendarData}
                                        eventOverlap
                                        dragRevertDuration={500}
                                        dragScroll
                                        droppable={true}
                                        showMultiDayTimes
                                        step={60}
                                        startAccessor="start"
                                        endAccessor="end"
                                        eventPropGetter={(event, start, end) => ({
                                            event,
                                            start,
                                            end,
                                            style: { backgroundColor: event.color }
                                        })}
                                    /> 
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default CalenderContain;