import { Container, Row, Form, Card, CardFooter } from 'reactstrap';
import { CardHeader, CardBody } from 'reactstrap'
import React, { Fragment, useEffect } from 'react';
import { H4 } from "../../../AbstractElements";
import { ApiRequestBuilder, ButtonBuilder, RegisteredInputsBuilder, ToastBuilder } from '../../../GlobalComponents/Builders';
import { useForm } from 'react-hook-form';

const FeedbackDocumentation = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmitFeedback = (feedback) => {

        ApiRequestBuilder('feedbackPost', null, feedback).then(function(results) {
            ToastBuilder('success', 'Feedback Sent Successfully')
        })
    }
    
    useEffect(() => {
        document.title = 'Document Upload'
    }, []);

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Card className='col-md-6'>
                        <CardHeader className="pb-0">
                            <H4 attrH4={{ className: "card-title mb-0" }}>{'Here to help!'}</H4>             
                        </CardHeader>
                        <CardBody>
                            <p>Have any ideas, concerns or questions? Need to <a href='https://calendly.com/jasonl-8/30min' target='_blank'>schedule a meeting</a>?</p>
                            <Form onSubmit={handleSubmit(onSubmitFeedback)}>
                                <CardBody>
                                        <RegisteredInputsBuilder 
                                            registrator={register}
                                            registratorErrors={errors}
                                            inputs={[
                                                {
                                                    label: 'Subject',
                                                    size: 12,
                                                    labelSize: 2,
                                                    name: 'feedbackSubject'
                                                },
                                                {
                                                    label: 'Message',
                                                    type: 'textarea',
                                                    size: 12,
                                                    labelSize: 2,
                                                    name: 'feedbackMessage'
                                                }
                                            ]}
                                        />
                                </CardBody>
                            
                                <CardFooter className="text-end">
                                    <ButtonBuilder label='Send Feedback' type='submit'/>
                                </CardFooter>
                            </Form>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
        </Fragment>
    )
}

export default FeedbackDocumentation;