import React, { Fragment, useState, useEffect } from 'react';
import TableBuilder, { ApiRequestBuilder, FormOptionsBuilder, ToastBuilder, RegisteredInputsBuilder, ModalButtonBuilder, ButtonBuilder } from '../../../../GlobalComponents/Builders';
import buildRandomId, { buildOptionsFromArray, resetAllFormFields, setInitialFormValues, sortArrayByKey } from '../../../../GlobalComponents/Helpers';
import { Container, Row, Card, CardBody, Form, Modal } from 'reactstrap'
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';

const SyncSettings = () => {
    
    let { syncId } = useParams();

    const [syncData, setSyncData] = useState([]);
    const [syncMappings, setSyncMappingsData] = useState([]);

    const [isProcessing, setIsProcessing] = useState(true);
    const { register, handleSubmit, setValue, resetField, formState: { errors } } = useForm();

    /**
     * This list should truncate to whatever the connection
     * are for this sync
     */
    const serviceList = [
        {
            name: 'ConnectWise Manage',
            key: 'cwm'
        },
        {
            name: 'ServiceNow',
            key: 'sn'
        }
    ]
    const attributeList = ['service', 'status', 'subType', 'type']

    const handleSyncMappingUpdate = (update, key = null) => {
        ApiRequestBuilder('ticketsSyncsSyncIdPatch', { syncId },
            { 
                id: key,
                syncId: syncData.id,
                clientId: (key ? update['clientId' + key] : update.clientId),
                attribute: (key ? update['attribute' + key] : update.attribute),
                source: (key ? update['source' + key] : update.source),
                cwmValue: (key ? update['cwmValue' + key] : update.cwmValue),
                snValue: (key ? update['snValue' + key] : update.snValue),
                snValueId: (key ? update['snValueId' + key] : update.snValueId)
            }, { setIsProcessing })
            .then(function(results) {
                getSyncData()
                resetAllFormFields(update, resetField)
            }
        )
    }

    const getSyncData = () => {
        
        ApiRequestBuilder('ticketsSyncsSyncIdGet', { syncId }, null, { setIsProcessing })
        .then(function(results) {
            if (results.data)
            {
                setSyncData(results.data)
                setSyncMappingsData(results.data.settings)
            }
        }
    )}    

    useEffect(() => {
    
        document.title = 'View Settings'

        getSyncData()
    }, []);

    return (
            <Fragment>
                <Row>
                    <Container>
                        <Card>
                            <CardBody>
                                <TableBuilder
                                    subHeader = {true}
                                    columnsToSearch = {['cwm_value', 'sn_value', 'source_value', 'sn_value_id']}
                                    data = {syncMappings}
                                    processing = {isProcessing}
                                    title = 'Mappings'
                                    actions={<ModalButtonBuilder title='Create Mapping' className='btn-primary'
                                        body={
                                            <Form id='create-mapping-form' onSubmit={handleSubmit((data) => handleSyncMappingUpdate(data, null))}>
                                                <RegisteredInputsBuilder
                                                    registrator={register}
                                                    registratorErrors={errors}
                                                    inputs={[
                                                        {
                                                            label: 'Client',
                                                            size: 12,
                                                            type: 'static',
                                                            name: 'clientId',
                                                            value: 19370
                                                        },
                                                        {
                                                            label: 'Attribute',
                                                            size: 12,
                                                            type: 'select',
                                                            name: 'attribute',
                                                            options: <FormOptionsBuilder options={buildOptionsFromArray(attributeList, { labelIsValue: true })}/>,
                                                        },
                                                        {
                                                            label: 'Source',
                                                            size: 12,
                                                            type: 'select',
                                                            name: 'source',
                                                            options: <FormOptionsBuilder options={buildOptionsFromArray(sortArrayByKey(serviceList, 'name'), { label: 'name', value:'key'})}/>,
                                                        },
                                                        {
                                                            label: 'Destination',
                                                            size: 12,
                                                            type: 'select',
                                                            name: 'destination',
                                                            options: <FormOptionsBuilder options={buildOptionsFromArray(sortArrayByKey(serviceList, 'name'), { label: 'name', value:'key'})}/>,
                                                        },
                                                        {
                                                            label: 'CWM Value',
                                                            size: 12,
                                                            name: 'cwmValue',
                                                        },
                                                        {
                                                            label: 'SN Value',
                                                            size: 12,
                                                            name: 'snValue',
                                                        },
                                                        {
                                                            label: 'SN Value ID',
                                                            size: 12,
                                                            name: 'snValueId',
                                                        }
                                                    ]}/>
                                            </Form>
                                        }
                                    />}
                                    columns = {[
                                        {
                                            name: 'Attribute',
                                            selector: (row) => row.attribute,
                                            sortable: true,
                                            center: true,
                                        },
                                        {
                                            name: 'Source',
                                            selector: (row) => row.source,
                                            sortable: true,
                                            center: true,
                                        },
                                        {
                                            name: 'Destination',
                                            selector: (row) => 'sn',
                                            sortable: true,
                                            center: true,
                                        },
                                        {
                                            name: 'CWM Value',
                                            selector: (row) => row.cwm_value,
                                            sortable: true,
                                            center: true,
                                        },
                                        {
                                            name: 'SN Value',
                                            selector: (row) => row.sn_value,
                                            sortable: true,
                                            center: true,
                                        },
                                        {
                                            name: 'SN Value ID',
                                            selector: (row) => row.sn_value_id,
                                            sortable: true,
                                            center: true,
                                        },
                                        {
                                            name: 'Manage', 
                                            selector: (row) => <ModalButtonBuilder title='View / Edit' className='btn-secondary m-1' 
                                                onClick={setInitialFormValues(row, setValue, 'setting_id')}
                                                formId = {'view-/-edit-form' + row.setting_id}
                                                body={
                                                    <Form id={'view-/-edit-form' + row.setting_id} onSubmit={handleSubmit(data => handleSyncMappingUpdate(data, row.setting_id))}>
                                                        <RegisteredInputsBuilder
                                                            registrator={register}
                                                            registratorErrors={errors}
                                                            inputs={[
                                                                {
                                                                    label: 'Client',
                                                                    size: 12,
                                                                    type: 'static',
                                                                    name: 'clientId' + row.setting_id,
                                                                    value: 19370
                                                                },
                                                                {
                                                                    label: 'Attribute',
                                                                    size: 12,
                                                                    type: 'select',
                                                                    name: 'attribute' + row.setting_id,
                                                                    options: <FormOptionsBuilder options={buildOptionsFromArray(attributeList, { labelIsValue: true })}/>,
                                                                },
                                                                {
                                                                    label: 'Source',
                                                                    size: 12,
                                                                    type: 'select',
                                                                    name: 'source' + row.setting_id,
                                                                    options: <FormOptionsBuilder options={buildOptionsFromArray(sortArrayByKey(serviceList, 'name'), { label: 'name', value:'key'})}/>,
                                                                },
                                                                {
                                                                    label: 'Destination',
                                                                    size: 12,
                                                                    type: 'select',
                                                                    name: 'destination' + row.setting_id,
                                                                    options: <FormOptionsBuilder options={buildOptionsFromArray(sortArrayByKey(serviceList, 'name'), { label: 'name', value:'key'})}/>,
                                                                },
                                                                {
                                                                    label: 'CWM Value',
                                                                    size: 12,
                                                                    name: 'cwmValue' + row.setting_id,
                                                                },
                                                                {
                                                                    label: 'SN Value',
                                                                    size: 12,
                                                                    name: 'snValue' + row.setting_id,
                                                                },
                                                                {
                                                                    label: 'SN Value ID',
                                                                    size: 12,
                                                                    name: 'snValueId' + row.setting_id,
                                                                }
                                                            ]}/>
                                                    </Form>
                                                }
                                            />,
                                            sortable: true,
                                            center: true,
                                        },
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </Container>
                </Row>
            </Fragment>
    );
};

export default SyncSettings;