import React, { Fragment, useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import { Btn, H3, H4, P, Image } from "../../../AbstractElements";
import { useForm } from 'react-hook-form';
import { Row, Col, Container, Card, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input } from 'reactstrap'
import TableBuilder, { RegisteredInputsBuilder, ApiRequestBuilder, ToastBuilder, ModalButtonBuilder, FormOptionsBuilder } from '../../../GlobalComponents/Builders';
import { buildOptionsFromArray, renameKeysCamelToSnake, setInitialFormValues } from '../../../GlobalComponents/Helpers';

document.title = 'View Service'

const Service = () => {

    let { serviceId } = useParams();

    const [serviceData, setServiceData] = useState('');
    const [resourceData, setResourceData] = useState([]);
    const [resourceKeyData, setResourceKeyData] = useState([]);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const getServiceData = () => {

        ApiRequestBuilder('servicesServiceIdResourcesGet', { serviceId }).then(function(results) {
            setServiceData(results.data[0].name)
            setResourceData(results.data[0].resources)
        })
    }

    const getResourceKeys = (resourceId) => {
        //ApiRequestBuilder('servicesServiceIdResourcesResourceIdKeysGet', { serviceId, resourceId }).then(function(results) {
         //   setResourceKeyData(results.data[0].keys)
        //})
    }

    const handleServiceResourceUpdate = (data, resourceId) => {
        
        function containsNumber(str, num) {
            const regex = new RegExp(`[a-zA-Z]+${num}$`);
            return regex.test(str);
        }

        var cleanFormData = {};

        for (const key in data)
        {
            if (containsNumber(key, resourceId) 
                    && !key.includes('jobId') 
                    && !key.includes('partnerId')
                    && !key.includes('parentResource')
                )
            {
                cleanFormData[key.replace(resourceId, '')] = data[key]
            }
        }

        ApiRequestBuilder('servicesServiceIdResourcesResourceIdPatch', { serviceId, resourceId }, renameKeysCamelToSnake(cleanFormData)).then(function(results) {
            getServiceData()
        })
    }

    const handleResourceCreate = (update) => {

        ApiRequestBuilder('servicesServiceIdResourcesPost', { serviceId }, { 
            resourceName: update['resourceNameNew'],
            resource : update['resourceNew'], 
            parentResourceId : update['parentResourceIdNew'],
            isSetupResource : update['isSetupResourceNew'],
        }).then(function(results) {
            getServiceData()
        })}

    useEffect(() => {

        getServiceData()

    }, []);

    return (
        <Fragment>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <TableBuilder
                                subHeader={true}
                                columnsToSearch={['name', 'resource', 'table_reference']}
                                data={resourceData}
                                processing={false}
                                pagination={false}
                                title = {serviceData + ' Resources'}
                                actions={<ModalButtonBuilder title='Create Resource' className='btn-primary'
                                    body={
                                        <Form id='create-resource-form' onSubmit={handleSubmit(handleResourceCreate)}>
                                            <RegisteredInputsBuilder
                                                registrator={register}
                                                registratorErrors={errors}
                                                inputs={[
                                                    {
                                                        label: 'Name',
                                                        size: 12,
                                                        name: 'resourceNameNew'
                                                    },
                                                    {
                                                        label: 'Resource',
                                                        size: 12,
                                                        name: 'resourceNew'
                                                    },
                                                    {
                                                        label: 'Parent Resource',
                                                        type: 'select',
                                                        size: 12,
                                                        required: false,
                                                        options: <FormOptionsBuilder options={buildOptionsFromArray([{name: null, resource_id: null}].concat(resourceData), { label : 'name', value: 'resource_id' })}/>,
                                                        name: 'parentResourceIdNew'
                                                    },
                                                    {
                                                        label: 'Is Setup Resource',
                                                        type: 'select',
                                                        size: 12,
                                                        required: false,
                                                        options: <FormOptionsBuilder options={buildOptionsFromArray([{id: 0, value: 'No'}, {id: 1, value: 'Yes'}], { label: 'value', value:'id' })}/>,
                                                        name: 'isSetupResourceNew'
                                                    }
                                                ]}/>
                                        </Form>
                                    }
                                />}
                                columns = {[
                                    {
                                        name: 'Resource ID',
                                        selector: (row) => row.resource_id,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Name',
                                        selector: (row) => row.name,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Resource',
                                        selector: (row) => row.resource,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Parent Resource',
                                        selector: (row) => row.parent_resource_id,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Table',
                                        selector: (row) => row.table_reference,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Manage',
                                        selector: (row) => <ModalButtonBuilder title='View / Edit' className='btn-secondary m-1' 
                                            onClick={() => {getResourceKeys(row.resource_id); setInitialFormValues(row, setValue, 'resource_id')}}
                                            formId = {'view-/-edit-form' + row.resource_id}
                                            body={
                                                <Form id={'view-/-edit-form' + row.resource_id} onSubmit={handleSubmit(data => handleServiceResourceUpdate(data, row.resource_id))}>
                                                    <RegisteredInputsBuilder
                                                        registrator={register}
                                                        registratorErrors={errors}
                                                        inputs={[
                                                            {
                                                                label: 'Name',
                                                                size: 12,
                                                                name: 'name' + row.resource_id
                                                            },
                                                            {
                                                                label: 'Resource',
                                                                size: 12,
                                                                name: 'resource' + row.resource_id
                                                            },
                                                            {
                                                                label: 'Parent Resource',
                                                                type: 'select',
                                                                size: 12,
                                                                required: false,
                                                                options: <FormOptionsBuilder options={buildOptionsFromArray([{name: null, resource_id: null}].concat(resourceData), { label : 'name', value: 'resource_id' })}/>,
                                                                name: 'parentResource' + row.resource_id
                                                            },
                                                            {
                                                                label: 'Table',
                                                                size: 12,
                                                                name: 'tableReference' + row.resource_id
                                                            }
                                                        ]}/>
                                                        <br/>
                                                        <ModalButtonBuilder title='View Keys' className='btn-secondary offset-md-4' size='large'
                                                            body={
                                                                <Container>
                                                                    <Card>
                                                                        <CardBody>
                                                                            <TableBuilder
                                                                                data={resourceKeyData}
                                                                                processing={false}
                                                                                pagination={false}
                                                                                columns = {[
                                                                                    {
                                                                                        name: 'ID',
                                                                                        selector: (row) => row.resource_key_id,
                                                                                        sortable: true,
                                                                                        center: true,
                                                                                        maxWidth: '5%'
                                                                                    },
                                                                                    {
                                                                                        name: 'Name',
                                                                                        selector: (row) => row.key,
                                                                                        sortable: true,
                                                                                        center: true,
                                                                                    }
                                                                                ]}
                                                                            />
                                                                        </CardBody>
                                                                    </Card>
                                                                </Container>
                                                            }
                                                        />
                                                </Form>
                                            }
                                        />,
                                        sortable: true,
                                        center: true,
                                        maxWidth: '13%'
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
        </Fragment>
    );
};
export default Service;