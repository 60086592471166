import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap'
import { ApiRequestBuilder } from '../../GlobalComponents/Builders';
import { AccountContext } from '../../GlobalComponents/Authentication/Accounts';
import { useContext } from 'react';

const ToolsList = () => {
    
    const { userSession } = useContext(AccountContext);

    const [availableToolData, setAvailableToolData] = useState([]);
    const [subscribedToolData, setSubscribedToolData] = useState([]);
    const [disabledToolData, setDisabledToolData] = useState([]);

    useEffect(() => {

        document.title = 'View Tools'

        ApiRequestBuilder('toolsGet').then(function(results) {

            if (results.data && results.data.length > 0)
            {
                var accessLevelFilter = localStorage.getItem('accessLevel') === 'global'

                setAvailableToolData(results.data.filter(tool => !tool.partner_id).filter(tool => !accessLevelFilter ? tool.is_active : true))
                setSubscribedToolData(accessLevelFilter ? results.data.filter(tool => tool.partner_id) : results.data.filter(tool => String(tool.partner_id) === userSession['custom:partnerId']).filter(tool => !accessLevelFilter ? tool.is_active : true))
                //setDisabledToolData(results.data).filter(tool => !tool.partner_id)
            }
        })
    }, []);

    return (
        <Fragment>
                <Row>
                    {
                        availableToolData.length > 0 ? <Fragment>
                            <h4>All Available Tools</h4>
                            {availableToolData.map(data => 
                                <Col sm="4" key={data.tool_id}>
                                    <Card>
                                        <CardBody>
                                            <CardHeader>
                                                <center>
                                                    {data.route ?
                                                    <h5><Link to={`${process.env.PUBLIC_URL}/tools/` + data.route}>{data.name}</Link></h5>
                                                    : <h5>{data.name}</h5> }
                                                </center>
                                            </CardHeader>
                                            <CardBody>
                                                <center>
                                                    <p>{data.description}</p>
                                                </center>
                                            </CardBody>
                                        </CardBody>
                                    </Card>
                                </Col>)}

                            {subscribedToolData.length > 0 ? 
                            <Fragment>
                                <h4>Partner Specific Tools</h4>
                                {subscribedToolData.map(data => 
                                    <Col sm="4" key={data.tool_id}>
                                        <Card>
                                            <CardBody>
                                                <CardHeader>
                                                    <center>
                                                        {<h4>{data.partner.name}</h4>}
                                                        {data.route ?
                                                        <h5><Link to={`${process.env.PUBLIC_URL}/tools/` + data.route}>{data.name}</Link></h5>
                                                        : <h5>{data.name}</h5> }
                                                    </center>
                                                </CardHeader>
                                                <CardBody>
                                                    <center>
                                                        <p>{data.description}</p>
                                                    </center>
                                                </CardBody>
                                            </CardBody>
                                        </Card>
                                    </Col>)}</Fragment> : null}
                        </Fragment> : <p>Loading Tools List...</p>
                    } 
                </Row>
        </Fragment>
    );
};
export default ToolsList;