import React, { Fragment, useState, useEffect, useContext } from 'react';
import TableBuilder, { ApiRequestBuilder, RegisteredInputsBuilder, ModalButtonBuilder, FormOptionsBuilder, ButtonBuilder, ToastBuilder } from '../../../../../../GlobalComponents/Builders';
import { buildOptionsFromArray, capitalizeFirstLetter } from '../../../../../../GlobalComponents/Helpers';
import { AccountContext } from '../../../../../../GlobalComponents/Authentication/Accounts';
import { Container, Row, Card, CardBody, Form, Col, CardHeader } from 'reactstrap'
import { useForm } from 'react-hook-form';
import moment from 'moment';

const CwmCleanerJob = ({jobData}) => {

    const { userSession } = useContext(AccountContext);
    
    const [renderPage, setRenderPage] = useState(false);

    const [includedRecords, setIncludedRecords] = useState([]);
    const [excludedRecords, setExcludedRecords] = useState([]);
    const [mergedRecords, setMergedRecords] = useState([]);
    const [deletedRecords, setDeletedRecords] = useState([]);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleJobAction = (action, formData, myCallback = null) => {
        formData.connectionId = jobData.destination_connection.id

        ApiRequestBuilder('jobsJobIdActionsActionNamePost', { jobId: jobData.id, actionName: action }, { ...formData })
            .then(function(results) {
                ToastBuilder('success', 'Action Taken Successfully')
                getRecords()
                myCallback ? myCallback(results) : null
            }
        )
    }

    const getRecords = () => {
        
        ApiRequestBuilder('toolsToolIdJobsJobIdDatasetsDatasetNameGet', { toolId: 10, jobId: jobData.id, datasetName: 'cleanup-records' })
        .then((results) => {
            if (results.data)
            {
                setIncludedRecords(results.data.filter(record => record.status === 'staged'))
                setDeletedRecords(results.data.filter(record => record.status === 'deleted'))
                setMergedRecords(results.data.filter(record => record.status === 'merged'))
                setExcludedRecords(results.data.filter(record => record.status === 'excluded'))
            }
            
            setRenderPage(true)
        }) 

    }

    useEffect(() => {   

        document.title = 'View Job'

        if (userSession['custom:partnerId'] !== String(jobData.partner_id) && userSession['custom:partnerId'] !== '0')
        {
            ToastBuilder('error', 'You don\'t have access to view this page')
        }
        else
        {
            getRecords() 
        }

    }, []);

    if (renderPage)
    { 
        return (
                <Fragment>
                    <Row>
                        <Col xl="6">
                            <Card>
                                <CardBody>
                                    <h4>Job Settings</h4>
                                    <br/>
                                    {jobData && jobData.destination_connection ? (<p><strong>Target Connection: </strong>{' ' + jobData.destination_connection.name}</p>) : ''}                               
                                </CardBody>
                            </Card>
                        </Col>
                        
                        <Col xl="6">
                            <Card>
                                <CardBody>
                                    <h4>Job Actions</h4>
                                    <br/>          
                                    <ModalButtonBuilder title='Stage Resource' className='btn-info'
                                        body={
                                            <Form id='stage-resource-form' onSubmit={handleSubmit((update) => handleJobAction('stage-resource-to-cleanup', update))}>
                                                <RegisteredInputsBuilder
                                                    registrator={register}
                                                    registratorErrors={errors}
                                                    inputs={[
                                                        {
                                                            label: 'Resource',
                                                            type: 'select',
                                                            size: 12,
                                                            required: false,
                                                            options: <FormOptionsBuilder options={buildOptionsFromArray([{name:'Companies', resource_id:97}], { label: 'name', value: 'resource_id' })} />,
                                                            name: 'resourceToClean',
                                                            value: 97 // Hardcoded to only support companies right now
                                                        },
                                                        {
                                                            label: 'Filter',
                                                            size: 12,
                                                            name: 'stagingFilter',
                                                            type: 'text'
                                                        }
                                                    ]}/>
                                            </Form>
                                        }
                                    />  
                                    <ModalButtonBuilder title='Execute Cleanup' className='btn-danger m-2'
                                        body={
                                            <Form id='execute-cleanup-form' onSubmit={handleSubmit(() => { 
                                                        handleJobAction('execute-cleanup', { resourceId: 97 })})
                                                    }>
                                                <p>Executing the cleanup will delete the resources in the defined target.</p>
                                                <br/>
                                                <p>There is <strong>NO</strong> way to revert this action.</p>
                                            </Form>
                                        }
                                    />    
                                    <ModalButtonBuilder title='Delete Job' className='btn-danger m-2'
                                        body={
                                            <Form id='delete-job-form' onSubmit={handleSubmit(() => { 
                                                        handleJobAction('delete-job', () => { window.location.href = process.env.PUBLIC_URL + '/tools/' + jobData.route })})
                                                    }>
                                                <p>Deleting a job will delete all data related to it. You should only delete a job if you created it out of error. Otherwise, we suggest that you archive it.</p>
                                                <br/>
                                                <p>There is <strong>NO</strong> way to revert this action.</p>
                                            </Form>
                                        }
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> 
                    <Row>
                        <Container>
                            <Card>
                                <CardBody>
                                    <TableBuilder
                                        subHeader = {true}
                                        columnsToSearch = {['company_name']}
                                        data = {includedRecords}
                                        processing = {false}
                                        title = 'Staged Records'
                                        columns = {[
                                            {
                                                name: 'ID',
                                                selector: (row) => row.company_id,
                                                sortable: true,
                                                center: true
                                            },
                                            {
                                                name: 'Name',
                                                selector: (row) => row.company_name,
                                                sortable: true,
                                                center: true
                                            },
                                            {
                                                name: 'Cleanup Status',
                                                selector: (row) => capitalizeFirstLetter(row.status),
                                                sortable: true,
                                                center: true
                                            },
                                            {
                                                name: 'Resource Status',
                                                selector: (row) => row.company_status,
                                                sortable: true,
                                                center: true
                                            },
                                            {
                                                name: '',
                                                selector: (row) => <ButtonBuilder label='Exclude' className='btn-secondary m-1' 
                                                    onClick={() => {
                                                        handleJobAction('update-cleanup-record', { resourceId: 97, status:'excluded', sourceRecId: row.source_rec_id })// Hardcoded to only support companies right now
                                                    }} />,
                                                sortable: true,
                                                center: true
                                            }
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        </Container>
                    </Row>
                    <Row>
                        <Container>
                            <Card>
                                <CardBody>
                                    <TableBuilder
                                        subHeader = {true}
                                        columnsToSearch = {['company_name']}
                                        data = {excludedRecords}
                                        processing = {false}
                                        title = 'Excluded Records'
                                        columns = {[
                                            {
                                                name: 'ID',
                                                selector: (row) => row.company_id,
                                                sortable: true,
                                                center: true
                                            },
                                            {
                                                name: 'Name',
                                                selector: (row) => row.company_name,
                                                sortable: true,
                                                center: true
                                            },
                                            {
                                                name: 'Cleanup Status',
                                                selector: (row) => capitalizeFirstLetter(row.status),
                                                sortable: true,
                                                center: true
                                            },
                                            {
                                                name: 'Resource Status',
                                                selector: (row) => row.company_status,
                                                sortable: true,
                                                center: true
                                            },
                                            {
                                                name: '',
                                                selector: (row) => <ButtonBuilder label='Include' className='btn-secondary m-1' 
                                                    onClick={() => 
                                                        handleJobAction('update-cleanup-record', { resourceId: 97, status:'staged', sourceRecId: row.source_rec_id })
                                                    }// Hardcoded to only support companies right now} 
                                                />,
                                                sortable: true,
                                                center: true
                                            }
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        </Container>
                    </Row>
                    <Row>
                        <Container>
                            <Card>
                                <CardBody>
                                    <TableBuilder
                                        subHeader = {true}
                                        columnsToSearch = {['company_name']}
                                        data = {mergedRecords}
                                        processing = {false}
                                        title = 'Merged Records'
                                        columns = {[
                                            {
                                                name: 'ID',
                                                selector: (row) => row.company_id,
                                                sortable: true,
                                                center: true
                                            },
                                            {
                                                name: 'Name',
                                                selector: (row) => row.company_name,
                                                sortable: true,
                                                center: true
                                            },
                                            {
                                                name: 'Cleanup Status',
                                                selector: (row) => capitalizeFirstLetter(row.status),
                                                sortable: true,
                                                center: true
                                            }
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        </Container>
                    </Row>
                    <Row>
                        <Container>
                            <Card>
                                <CardBody>
                                    <TableBuilder
                                        subHeader = {true}
                                        columnsToSearch = {['company_name']}
                                        data = {deletedRecords}
                                        processing = {false}
                                        title = 'Deleted Records'
                                        columns = {[
                                            {
                                                name: 'ID',
                                                selector: (row) => row.company_id,
                                                sortable: true,
                                                center: true
                                            },
                                            {
                                                name: 'Name',
                                                selector: (row) => row.company_name,
                                                sortable: true,
                                                center: true
                                            },
                                            {
                                                name: 'Cleanup Status',
                                                selector: (row) => capitalizeFirstLetter(row.status),
                                                sortable: true,
                                                center: true
                                            }
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        </Container>
                    </Row>
                    
                </Fragment>
        );
    }
    else 
    {
        if (userSession['custom:partnerId'] !== String(jobData.partner_id) && userSession['custom:partnerId'] !== '0')
        {
            return <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />
        }
        return <p>Loading Job Information...</p>
    }
};

export default CwmCleanerJob;