import React, { Fragment, useState, useEffect } from 'react';
import { ApiRequestBuilder, ToastBuilder } from '../../../GlobalComponents/Builders';
import { Link, useParams } from 'react-router-dom';
import TimeEntryLocationAdjuster from './components/TimeEntryLocationAdjuster';
import BackorderNotificationSurvey from './components/BackorderNotificationSurvey';
import BulkProductUpdater from './components/BulkProductUpdater';
import DataMigrationTool from './components/DataMigrationTool';
import ProjectImporter from './components/ProjectImporter';
import { sortArrayByKey } from '../../../GlobalComponents/Helpers';
import InvoiceEntryAdjuster from './components/InvoiceEntryAdjuster';
import CwmCleaner from './components/CwmCleaner';
import OrderEntryPortal from './components/OrderEntryPortal';
import CommissionTracker from './components/CommissionTracker';
import ShipmentManager from './components/ShipmentManager';

const Tool = () => {
    
    let { toolId } = useParams();

    const [jobData, setJobData] = useState([]);
    const [toolData, setToolData] = useState({});
    const [connectionData, setConnectionData] = useState([]);

    const handleJobCreate = (newJobData) => {

        newJobData.toolId = toolData.tool_id
        newJobData.sourceConnection = newJobData.sourceConnection !== undefined ? newJobData.sourceConnection : newJobData.destinationConnection
        
        ApiRequestBuilder('toolsToolIdJobsPost', {toolId}, newJobData).then(function(results){
            
            if (results.data.id)
            {
                ToastBuilder('success', 'Job Creation Successful')
                window.location.href = process.env.PUBLIC_URL + '/tools/' + toolData.route + '/jobs/' + String(results.data.id)
            }
            else ToastBuilder('error', 'Unable to Create Job')

        }).catch((err) => {
            console.log(err);
            ToastBuilder('error', 'Unable to Create Job')
        })
    }

    const renderToolPage = (toolId) => {
        switch(toolId)
        {
            // TODO, make this component render dynamic based on route, no switch
            case 'time-entry-location-adjuster':
                return <TimeEntryLocationAdjuster toolData={toolData} jobData={jobData} connectionData={connectionData} handleJobCreate={handleJobCreate}/>
            case 'backorder-notification-survey':
                return <BackorderNotificationSurvey toolData={toolData} jobData={jobData} connectionData={connectionData} handleJobCreate={handleJobCreate}/>
            case 'bulk-product-updater':
                return <BulkProductUpdater toolData={toolData} jobData={jobData} connectionData={connectionData} handleJobCreate={handleJobCreate}/>
            case 'data-migration':
                return <DataMigrationTool toolData={toolData} jobData={jobData} connectionData={connectionData} handleJobCreate={handleJobCreate}/>
            case 'project-importer':
                return <ProjectImporter toolData={toolData} jobData={jobData} connectionData={connectionData} handleJobCreate={handleJobCreate}/>
            case 'invoice-entry-adjuster':
                return <InvoiceEntryAdjuster toolData={toolData} jobData={jobData} connectionData={connectionData} handleJobCreate={handleJobCreate}/>
            case 'cwm-cleaner':
                return <CwmCleaner toolData={toolData} jobData={jobData} connectionData={connectionData} handleJobCreate={handleJobCreate}/>
            case 'order-entry-portal':
                return <OrderEntryPortal toolData={toolData} jobData={jobData} connectionData={connectionData} handleJobCreate={handleJobCreate}/>
            case 'commission-tracker':
                return <CommissionTracker toolData={toolData} jobData={jobData} connectionData={connectionData} handleJobCreate={handleJobCreate}/>
            case 'shipment-manager':
                return <ShipmentManager toolData={toolData} jobData={jobData} connectionData={connectionData} handleJobCreate={handleJobCreate}/>
            default:
                return <p>We have not yet finalized this tool...</p>
        }
    }

    useEffect(() => {
    
        document.title = 'Tool Details'

        ApiRequestBuilder('toolsToolIdGet', { toolId }).then(function(results){
            
            setToolData(results.data ? results.data : {})
        })

        ApiRequestBuilder('connectionsGet')
            .then(function(results) {
                results.data && results.data.length > 0 ? setConnectionData(sortArrayByKey(results.data, 'name')) : null
            }
        )

        ApiRequestBuilder('toolsToolIdJobsGet', { toolId }).then(function(results){
            setJobData(results.data && results.data.length > 0 ? results.data : [])
        })

    }, []);

    return (
        <Fragment>
            {renderToolPage(toolId)}
        </Fragment>
    );
};

export default Tool;