import React, { Fragment } from 'react';
import TableBuilder, { FormOptionsBuilder, RegisteredInputsBuilder, ModalButtonBuilder, ButtonBuilder } from '../../../../GlobalComponents/Builders';
import { buildOptionsFromArray, groupObjectsByKey, sortArrayByKey } from '../../../../GlobalComponents/Helpers';
import { Container, Row, Card, CardBody, Form, Modal } from 'reactstrap'
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import parse from 'html-react-parser'

const DataMigrationTool = ({toolData, jobData, connectionData, handleJobCreate}) => {

    const { register, handleSubmit, formState: { errors } } = useForm();

    var requiredPermissions = [
        {
            module: 'Admin',
            permission: 'ALL',
            levels: 'Add, Edit, Delete, Inquire'
        }
    ]

    return (
        <Fragment>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <h4>{toolData.name}</h4>
                            <br/>
                            <h5>Description:</h5>
                            {toolData.html_description ? parse(toolData.html_description) : ''}
                            <br/>
                            <h5>Permissions Required</h5>
                            <TableBuilder
                                data={requiredPermissions}
                                processing={false}
                                title = ''
                                columns = {[
                                    {
                                        name: 'Module',
                                        selector: (row) => row.module,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Permission',
                                        selector: (row) => row.permission,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Levels',
                                        selector: (row) => row.levels,
                                        sortable: true,
                                        center: true,
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <TableBuilder
                                data = {jobData}
                                processing = {false}
                                title = 'Jobs'
                                actions = {
                                    connectionData.length > 0 ? 
                                        <ModalButtonBuilder title='Create Job' className='btn-secondary'
                                            body = {
                                                <Form id='create-job-form' onSubmit={handleSubmit((data) => handleJobCreate(data, toolData))}>
                                                    <RegisteredInputsBuilder
                                                        registrator={register}
                                                        registratorErrors={errors}
                                                        inputs={[
                                                            {
                                                                label: 'Name',
                                                                size: 12,
                                                                name: 'name',
                                                                placeholder: 'Fintech Migration'
                                                            },
                                                            {
                                                                label: 'Source Connection',
                                                                type: 'select',
                                                                size: 12,
                                                                name: 'sourceConnection',
                                                                options: <FormOptionsBuilder options={buildOptionsFromArray(connectionData, { label:'name', value:'id', groupKey:'type', groupLabel:'Type'})} isGroup={true}/>,
                                                            },
                                                            {
                                                                label: 'Destination Connection',
                                                                type: 'select',
                                                                size: 12,
                                                                name: 'destinationConnection',
                                                                options: <FormOptionsBuilder options={buildOptionsFromArray(connectionData, { label:'name', value:'id', groupKey:'type', groupLabel:'Type'})} isGroup={true}/>
                                                            },
                                                            {
                                                                label: 'Test Connection',
                                                                type: 'select',
                                                                size: 12,
                                                                name: 'testConnection',
                                                                options: <FormOptionsBuilder options={buildOptionsFromArray(connectionData.concat([{name:'No Test Connection', id:0, type:'----'}]), { label:'name', value:'id', groupKey:'type', groupLabel:'Type'})} isGroup={true}/>,
                                                                helpText: 'Where can we safely create resources for testing?'
                                                            },
                                                            {
                                                                label: 'Job Type',
                                                                type: 'select',
                                                                size: 12,
                                                                inputSize: 4,
                                                                name: 'jobType',
                                                                options: <FormOptionsBuilder options={buildOptionsFromArray([
                                                                    {id: 'migration', value: 'Migration'}, 
                                                                    {id: 'one-way-sync', value: 'One-Way Sync'}, 
                                                                    {id: 'two-way-sync', value: 'Two-Way Sync'}, 
                                                                    {id: 'setup-tables', value: 'Setup Table Mapping'}
                                                                ], { label: 'value', value:'id' })}/>,
                                                            },
                                                            {
                                                                label: 'Copy Settings?',
                                                                type: 'select',
                                                                size: 12,
                                                                name: 'jobToCopyFrom',
                                                                options: <FormOptionsBuilder options={buildOptionsFromArray([{ name: 'No, this is a new job', id: 0 }].concat(jobData), { label:'name', value:'id'})}/>,
                                                                helpText: 'Should we use mappings from another job?'
                                                            }
                                                        ]}/>
                                                </Form>
                                            }
                                        /> : 
                                        <Fragment>
                                            <p><strong>You will need to create a connection before creating a job for this tool</strong> - </p>
                                            <ButtonBuilder label = 'Create Connection' onClick={() => window.location.href = process.env.PUBLIC_URL + '/connections'}/>
                                        </Fragment>
                                }
                                columns = {[
                                    {
                                        name: 'Job #',
                                        selector: (row) => row.id,
                                        sortable: true,
                                        center: true,
                                        maxWidth: '7%'
                                    },
                                    {
                                        name: 'Name',
                                        selector: (row) => row.name,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Is Enabled',
                                        selector: (row) => row.is_enabled ? 'Yes' : 'No',
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Is Sync',
                                        selector: (row) => row.is_sync ? 'Yes' : 'No',
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Is Production',
                                        selector: (row) => row.is_production ? <span style={{ color: 'red' }}>Yes</span> : 'No',
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Is Archived',
                                        selector: (row) => row.is_archived ? 'Yes' : 'No',
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Details',
                                        selector: (row) => <Link to={`${process.env.PUBLIC_URL}/tools/` + toolData.route + '/jobs/' + row.id}>{'View / Edit'}</Link>,
                                        sortable: true,
                                        center: true,
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
        </Fragment>
    )
}

export default DataMigrationTool;