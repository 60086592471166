import { Container, Row, Col, CardBody, Card } from 'reactstrap';
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { H4, P } from '../../../AbstractElements';
import CountUp from 'react-countup';
import { ApiRequestBuilder, TilesBuilder } from '../../../GlobalComponents/Builders';
import { groupObjectsByKey } from '../../../GlobalComponents/Helpers';
import { AccountContext } from '../../../GlobalComponents/Authentication/Accounts';


const Dashboard = () => {

    const [numberOfPartners, setNumberOfPartners] = useState(0);
    const [numberOfActiveTools, setNumberOfActiveTools] = useState(0);
    const [numberOfCustomTools, setNumberOfCustomTools] = useState(0);
    const [numberOfActiveProjects, setNumberOfActiveProjects] = useState(0);

    const { userSession } = useContext(AccountContext)

    useEffect(() => {

        document.title = 'View Dashboard'

        ApiRequestBuilder('partnersGet')
            .then(function (results) {
                setNumberOfPartners(results.data ? results.data.length : 0)
            })

        ApiRequestBuilder('developmentProjectsGet')
            .then(function (results) {

                if (results.data) {
                    var groupedProjects = groupObjectsByKey(results.data.tickets, 'status')
                    setNumberOfActiveProjects(groupedProjects['Pending Client'].length + groupedProjects['In Progress'].length)
                }
            })

        ApiRequestBuilder('toolsGet').then(function (results) {
            if (results.data) {
                setNumberOfActiveTools(results.data.length)
                setNumberOfCustomTools(results.data.filter(element => element.partner_id).length)
            }
        })

    }, []);

    var tiles = [
        {
            id: 1,
            icon: 'Users',
            title: 'Partners',
            count: <CountUp end={numberOfPartners} duration={3} />,
            backgroundIcon: 'Users'
        },
        {
            id: 2,
            icon: 'ShoppingBag',
            title: 'Active Tools',
            count: <CountUp end={numberOfActiveTools} duration={2} />,
        },
        {
            id: 3,
            icon: 'ShoppingBag',
            title: 'Custom Tools',
            count: <CountUp end={numberOfCustomTools} duration={2} />,
        },
        {
            id: 4,
            icon: 'MessageCircle',
            title: 'Active Projects',
            count: <CountUp end={numberOfActiveProjects} duration={2} />,
        }
    ]

    return (
        <Fragment>
            <Container fluid={true}>
                <TilesBuilder data={tiles} />
            </Container>
        </Fragment>
    );
};

export default Dashboard;