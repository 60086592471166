import React, { Fragment, useState, useEffect, useContext } from 'react';
import TableBuilder, { ApiRequestBuilder, ButtonBuilder, ModalButtonBuilder, RegisteredInputsBuilder, ToastBuilder, FormOptionsBuilder, ModalLinkBuilder, TilesBuilder } from '../../../../GlobalComponents/Builders';
import { buildOptionsFromArray, convertTimestampToTimePicker } from '../../../../GlobalComponents/Helpers';
import { Container, Row, Col, Card, CardBody, Form, NavItem, NavLink, Nav, TabContent, TabPane } from 'reactstrap'
import { H4 } from '../../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { User, UserPlus, Calendar, Key, Unlock, Users } from 'react-feather';
import CountUp from 'react-countup';
import { AccountContext } from '../../../../GlobalComponents/Authentication/Accounts';
import moment from 'moment';


const PartnerSubscriptionList = () => {

    const { userSession } = useContext(AccountContext);

    const [tileSubscriptionData, setTileSubscriptionData] = useState([])
    const [subscriptionData, setSubscriptionData] = useState([])
    const [billingDate, setBillingDate] = useState(moment(new Date()).format('D / M / YYYY'))
    const [isProcessing, setIsProcessing] = useState(true);

    var tiles = [
        {
            id: 0,
            icon: 'Users',
            title: 'NexNow Subscriptions',
            count: <CountUp end={tileSubscriptionData.length} duration={3} useEasing={true} />,
            backgroundIcon: 'Users',
        },
        {
            id: 1,
            icon: 'Calendar',
            title: 'Next Billing Date',
            content: billingDate,
            backgroundIcon: 'Calendar',
            omit: subscriptionData.length === 0
        },
    ]

    const getSubscriptionData = () => {
        ApiRequestBuilder('subscriptionsGet', null, null, { setIsProcessing }).then(function (results) {
            if (results.data && results.data.length > 0) {
                setTileSubscriptionData(results.data)
                setSubscriptionData(results.data.filter(element => element.partner_id === parseInt(userSession['custom:partnerId']) && element.is_active))
                const now = moment(new Date())
                const m = (((now.month() + 1) % 12) + 1)
                setBillingDate(( m < 10 ? '0' + m : m ) + ' / 01 / ' + ( now.month() === 11 ? now.year() + 1 : now.year()))
            }
        })
    }

    useEffect(() => {
        document.title = 'Partner Subscriptions'
        getSubscriptionData()
    }, []);


    return (
        <Fragment>
            <Container fluid={true}>
                <Fragment>
                    <TilesBuilder data={tiles} />
                </Fragment>
            </Container>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <TableBuilder
                                subHeader={true}
                                columnsToSearch={['tool_name']}
                                data={subscriptionData}
                                processing={isProcessing}
                                title='Subscriptions'
                                columns={[
                                    {
                                        name: 'Tool',
                                        selector: (row) => row.tool_name,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Type',
                                        selector: (row) => 'Monthly',
                                        sortable: true,
                                        center: true,
                                        maxWidth: '18%'
                                    },
                                    {
                                        name: 'Status',
                                        selector: (row) => row.is_active ? <p style={{ color: 'green' }}>Active</p> : 'Unsubscribed',
                                        sortable: true,
                                        center: true,
                                        maxWidth: '18%',
                                    },
                                    {
                                        name: 'Subscription Date',
                                        selector: (row) => moment(new Date(row.subscribed_at * 1000)).format('MM-DD-YYYY'),
                                        sortable: true,
                                        center: true,
                                        maxWidth: '25%',
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
        </Fragment>
    );
};

export default PartnerSubscriptionList;