import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Row, Card, CardBody, Form, Container } from 'reactstrap'
import TableBuilder, { ButtonBuilder, FormOptionsBuilder, ModalButtonBuilder, RegisteredInputsBuilder, ToastBuilder } from '../../../../GlobalComponents/Builders';
import { buildOptionsFromArray } from '../../../../GlobalComponents/Helpers';
import { AccountContext } from '../../../../GlobalComponents/Authentication/Accounts';
import parse from 'html-react-parser'

const CwmCleaner = ({toolData, jobData, connectionData, handleJobCreate}) => {

    const { userSession } = useContext(AccountContext);

    const { register, handleSubmit, resetField, getValues, formState: { errors } } = useForm();

    const handlePreJobCreate = (data) => {

        handleJobCreate({
            name: data.name,
            toolId: 10,
            sourceConnection: data.destinationConnection,
            destinationConnection: data.destinationConnection
        })
    }
    
    var requiredPermissions = [
        {
            module: 'Companies',
            permission: 'Company Maintenance',
            levels: 'Add, Edit, Delete, Inquire'
        },
        {
            module: 'System',
            permission: 'Mass Maintenance',
            levels: 'Add, Edit, Delete, Inquire'
        }
    ]

    const [activePartnerSubscription, setActivePartnerSubscription] = useState(false);
    
    useEffect(() => {
        if (Object.entries(toolData).length > 0)
        {
            toolData.subscriptions.forEach(subscription => {
                if (subscription.partner_id === Number(userSession['custom:partnerId']) && subscription.is_active)
                {
                    setActivePartnerSubscription(true)
                }
            })
        }
    })

    return (
        <Fragment>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <h4>{toolData.name}</h4>
                            <br/>
                            <h5>Description</h5>
                            {toolData.html_description ? parse(toolData.html_description) : ''}
                            <br/>
                            <h5>Permissions Required</h5>
                            <TableBuilder
                                data={requiredPermissions}
                                processing={false}
                                title = ''
                                columns = {[
                                    {
                                        name: 'Module',
                                        selector: (row) => row.module,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Permission',
                                        selector: (row) => row.permission,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Levels',
                                        selector: (row) => row.levels,
                                        sortable: true,
                                        center: true,
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
            <Row>
                <Card>
                    <CardBody>
                        <TableBuilder
                            data = {activePartnerSubscription ? jobData : []}
                            processing={false}
                            title = 'Jobs'
                            actions={
                                activePartnerSubscription ? connectionData.length > 0 ? 
                                <ModalButtonBuilder title='Create Job' className='btn-secondary'
                                    body={
                                        <Form id='create-job-form' onSubmit={handleSubmit(handlePreJobCreate)}>
                                            <RegisteredInputsBuilder
                                                registrator={register}
                                                registratorErrors={errors}
                                                inputs={[
                                                    {
                                                        label: 'Name',
                                                        size: 12,
                                                        name: 'name'
                                                    },
                                                    {
                                                        label: 'Target Connection',
                                                        type: 'select',
                                                        size: 12,
                                                        name: 'destinationConnection',
                                                        options: <FormOptionsBuilder options={buildOptionsFromArray(connectionData.filter(connection => connection.type !== 'csv'), { label:'name', value:'id', groupKey:'type', groupLabel:'Type'})} isGroup={true}/>,
                                                    }
                                                ]}/>
                                        </Form>
                                    }
                                /> : 
                                <Fragment>
                                    <p><strong>You will need to create a connection before creating a job for this tool</strong> - </p>
                                    <ButtonBuilder label = 'Create Connection' onClick={() => window.location.href = process.env.PUBLIC_URL + '/connections'}/>
                                </Fragment> : 
                                <Fragment>
                                    <p><strong>You will need to subscribe to this tool before creating a job</strong></p>
                                </Fragment>
                            }
                            columns = {[
                                {
                                    name: 'Job #',
                                    selector: (row) => row.id,
                                    sortable: true,
                                    center: true,
                                    maxWidth: '7%'
                                },
                                {
                                    name: 'Name',
                                    selector: (row) => row.name,
                                    sortable: true,
                                    center: true,
                                },
                                {
                                    name: 'Details',
                                    selector: (row) => <Link to={`${process.env.PUBLIC_URL}/tools/` + toolData.route + '/jobs/' + row.id}>{'View / Edit'}</Link>,
                                    sortable: true,
                                    center: true,
                                }
                            ]}
                        />
                    </CardBody>
                </Card>
            </Row>
        </Fragment>
    );
};
export default CwmCleaner; 