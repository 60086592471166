import React, { Fragment, useState, useEffect } from 'react';
import TableBuilder, { ApiRequestBuilder, FormOptionsBuilder, ToastBuilder, RegisteredInputsBuilder, ModalButtonBuilder, ButtonBuilder } from '../../../GlobalComponents/Builders';
import { buildOptionsFromArray, setInitialFormValues, sortArrayByKey } from '../../../GlobalComponents/Helpers';
import { Container, Row, Card, CardBody, Form, Modal } from 'reactstrap'
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

const PsaSync = () => {
    
    let { syncId } = useParams();

    const [syncData, setSyncData] = useState([]);

    const [isProcessing, setIsProcessing] = useState(true);
    const [connectionData, setConnectionData] = useState([]);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const handleSyncCreate = (update, key = null) => {

        ApiRequestBuilder('ticketsSyncsSyncIdPatch', { syncId },
            { 
                clientId: update.clientId,
                attribute: update.attribute,
                source: update.source,
                cwmValue: update.cwmValue,
                snValue: update.snValue,
                snValueId: update.snValueId
            }, { setIsProcessing })
            .then(function(results) {
                getSyncData()
            }
        )
    }

    const getSyncData = () => {
        
        ApiRequestBuilder('ticketsSyncsGet', null, null, { setIsProcessing })
        .then(function(results) {
            if (results.data && results.data.length > 0)
            {
                setSyncData(results.data)
            }
        }
    )}    

    useEffect(() => {
    
        document.title = 'View Settings'

        getSyncData()

        ApiRequestBuilder('connectionsGet')
            .then(function(results) {

                if (results.data && results.data.length > 0)
                {
                    setConnectionData(results.data && results.data.length > 0 ? results.data.filter(connection => connection.type !== 'csv') : [])
                }
                else
                {
                    ToastBuilder('info', 'Please go create some connections first.')
                }
            }
        )

    }, []);

    return (
            <Fragment>
                <Row>
                    <Container>
                        <Card>
                            <CardBody>
                                <TableBuilder
                                    subHeader = {true}
                                    data = {syncData}
                                    processing={isProcessing}
                                    title = 'Syncs'
                                    actions={<ModalButtonBuilder title='Create Sync' className='btn-secondary'
                                        body={
                                            <Form id='create-sync-form' onSubmit={handleSubmit(handleSyncCreate)}>
                                                <RegisteredInputsBuilder
                                                    registrator={register}
                                                    registratorErrors={errors}
                                                    inputs={[
                                                        {
                                                            label: 'Name',
                                                            size: 12,
                                                            name: 'syncName'
                                                        },
                                                        {
                                                            label: 'Source Connection',
                                                            type: 'select',
                                                            size: 12,
                                                            name: 'sourceConnection',
                                                            options: <FormOptionsBuilder options={buildOptionsFromArray(sortArrayByKey(connectionData, 'name'), { label: 'name', value:'id'})}/>,
                                                        },
                                                        {
                                                            label: 'Destination Connection',
                                                            type: 'select',
                                                            size: 12,
                                                            name: 'destinationConnection',
                                                            options: <FormOptionsBuilder options={buildOptionsFromArray(sortArrayByKey(connectionData, 'name'), { label: 'name', value:'id'})}/>,
                                                        }
                                                    ]}/>
                                            </Form>
                                        }
                                    />}
                                    columns = {[
                                        {
                                            name: 'Name',
                                            selector: (row) => row.name,
                                            sortable: true,
                                            center: true,
                                        },
                                        {
                                            name: 'Details',
                                            selector: (row) => <Link to={`${process.env.PUBLIC_URL}/tools/psa-sync/` + row.id}>{'View'}</Link>,
                                            sortable: true,
                                            center: true,
                                        }
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </Container>
                </Row>
            </Fragment>
    );
};

export default PsaSync;