import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TableBuilder, { RegisteredInputsBuilder, ApiRequestBuilder, ToastBuilder, ButtonBuilder, ModalLinkBuilder, FormOptionsBuilder } from '../../../../../../../GlobalComponents/Builders';
import { Row, Col, CardHeader, CardBody, CardFooter, Form, Card } from 'reactstrap'
import { useParams } from 'react-router-dom';
import { H4 } from '../../../../../../../AbstractElements';
import { buildOptionsFromArray } from '../../../../../../../GlobalComponents/Helpers';

const DMTSearch = ({toolData, jobData, connectionData}) => {

    document.title = 'Search Records'

    let { jobId } = useParams();
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [recordData, setRecordData] = useState([]);
    const [resources, setResources] = useState([]);
    const [renderResults, setRenderResults] = useState(false);

    const getResources = () => {

        var serviceId = jobData && jobData.source_connection ? jobData.source_connection.service_id : null
        if (!serviceId) return false
        
        ApiRequestBuilder('servicesServiceIdResourcesGet', { serviceId: jobData.source_connection.service_id }).then(function(results) {
            if (results.data)
            {
                setResources(resources.concat(results.data[0].resources))
            }
        })
        
        ApiRequestBuilder('servicesServiceIdResourcesGet', { serviceId: jobData.destination_connection.service_id }).then(function(results) {
            if (results.data)
            {
                setResources(resources.concat(results.data[0].resources))
            }
        })
    }

    const onRecordSearch = (searchQuery) => {

        setRecordData([])
        setRenderResults(false)
        ApiRequestBuilder('dmtJobsJobIdRecordsSearchPost', { jobId }, searchQuery).then(function(results){
            
            if (results.data && results.data.length > 0)
            {
                setRecordData(results.data)
                setRenderResults(true)
                ToastBuilder('success', 'Record Search Successful')
            } else ToastBuilder('info', 'No Records Matching Search')

        }).catch((err) => {
            console.log(err);
            ToastBuilder('error', 'Unable to Search Records')
        })
    }

    useEffect(() => {   

        if (resources.length === 0) getResources()

    }, []);

    return (
        <Fragment>
            { resources && resources.length > 0 ?
                <Form id='search-records-form' className='card' onSubmit={handleSubmit(onRecordSearch)}>
                    <CardHeader className='pb-0'>
                        <H4 attrH4={{ className: 'card-title mb-0' }}>Search Records</H4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <RegisteredInputsBuilder 
                                registrator={register}
                                registratorErrors={errors}
                                inputs={[
                                    {
                                        label: 'Resource',
                                        required: true,
                                        name: 'resourceId',
                                        type: 'select',
                                        options: <FormOptionsBuilder options={buildOptionsFromArray(resources, { label: 'name', value: 'resource_id'})}/>
                                    },
                                    {
                                        label: 'Source ID',
                                        name: 'sourceRecId',
                                        type: 'number',
                                    },
                                    {
                                        label: 'Dest. ID',
                                        type: 'number',
                                        name: 'destinationResourceRecId'
                                    },
                                    {
                                        label: 'Status',
                                        type: 'select',
                                        helpText: 'Find records in a specific status',
                                        name: 'recordStatus',
                                        options: <FormOptionsBuilder options={buildOptionsFromArray(['staged', 'queued', 'complete', 'deleted', 'error', 'missing-mapping'], { labelIsValue: true })}/>
                                    },
                                ]}
                            />
                        </Row>
                    </CardBody>
                    <CardFooter className='text-end'>
                        <ButtonBuilder label='Search Records'/>
                        <ButtonBuilder form='' label='Reset' className='btn-secondary m-1' onClick={() => document.getElementById("search-records-form").reset()}/>
                    </CardFooter>
                </Form> : null }
            { renderResults ? 
                <Col xl="12">
                    <Card>
                        <CardBody>
                            <TableBuilder
                                title = 'Search Results'
                                data = {recordData}
                                processing = {false}
                                columns = {[
                                    {
                                        name: 'Rec ID',
                                        selector: (row) => row.source_rec_id,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Dest. Rec ID',
                                        selector: (row) => row.destination_resource_rec_id,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Status',
                                        selector: (row) => row.status === 'error' ? <ModalLinkBuilder label='View Error' title='Error Message' body={<pre>{JSON.stringify(row.error_message, null, 4)}</pre>} size='md'/> : row.status,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Preview',
                                        selector: (row) => <ModalLinkBuilder label='View' title='Record Data' body={<pre>{JSON.stringify(row.resource_data, null, 4)}</pre>} size='md'/>,
                                        sortable: true,
                                        center: true,
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Col> : null}
        </Fragment>
    );
};

export default DMTSearch;
