import React, { Fragment, useEffect, useState } from 'react';
import { Row, Card, CardHeader, CardBody, Form, Col, CardFooter } from 'reactstrap'
import TableBuilder, { ApiRequestBuilder, ButtonBuilder, FormOptionsBuilder, RegisteredInputsBuilder, ToastBuilder } from '../../../../GlobalComponents/Builders';
import { useForm } from 'react-hook-form';
import { buildOptionsFromArray, sortArrayByKey, uploadPresignedFile } from '../../../../GlobalComponents/Helpers';

const Jobs = () => {

    const [toolData, setToolData] = useState([]);

    const { register, getValues, handleSubmit, setValue, resetField, formState: { errors } } = useForm();
    
    const handleDocumentUpload = (file) => {

        if (file['clientFileToUpload'].length === 0)
        {
            ToastBuilder('error', 'Please Select a File to Upload')
        }
        else
        {
            uploadPresignedFile(file['clientFileToUpload'], 'filesUploadPost', null, { 
                bucket: process.env.REACT_APP_FILES_BUCKET,
                key: file['clientFileToUpload'][0].name,
                type: 'bulk-upload-' + getValues('object')
            }, () => {}, { timeout: '5000' })
        }

        /**
         * Even if no file is selected in the input, 
         * some form of the state may be holding onto it
         */
        resetField('clientFileToUpload')
    }

    useEffect(() => {

        document.title = 'Bulk Updater'

        ApiRequestBuilder('toolsGet').then(function(results) {
            setToolData(results.data && results.data.length > 0 ? results.data : [])
        })

    }, []);

    return (
        <Fragment>
            <Col sm="6">
                <Card>
                    <CardHeader className='pb-0'>
                        <h5>Task</h5>
                    </CardHeader>
                    <CardBody>
                        <Form id='upload-document-form' onSubmit={handleSubmit(handleDocumentUpload)}>
                            <RegisteredInputsBuilder 
                                registrator={register}
                                registratorErrors={errors}
                                inputs={[
                                    {
                                        label: 'Source Connection',
                                        type: 'select',
                                        size: 12,
                                        name: 'sourceConnection',
                                        options: <FormOptionsBuilder options={buildOptionsFromArray(sortArrayByKey([{'name' : 'ConnectWise-Manage', item_id: 1}], 'name'), { label: 'name', value:'item_id'})}/>,
                                    },
                                    {
                                        label: 'Destination Connection',
                                        type: 'select',
                                        size: 12,
                                        name: 'destinationConnection',
                                        options: <FormOptionsBuilder options={buildOptionsFromArray(sortArrayByKey([{'name' : 'ConnectWise-Manage', item_id: 1}], 'name'), { label: 'name', value:'item_id'})}/>,
                                    },
                                    {
                                        label: 'Object',
                                        type: 'select',
                                        size: 12,
                                        name: 'object',
                                        options: <FormOptionsBuilder options={buildOptionsFromArray(sortArrayByKey([{'name' : 'Configurations', item_id: 'configurations'}], 'name'), { label: 'name', value:'item_id'})}/>,
                                        helpText: 'What needs to be updated or created?'
                                    }
                                ]}
                            /> 
                        </Form>
                    </CardBody>
                    <CardFooter className="text-end">
                        <ButtonBuilder label = 'Create Task' form='upload-document-form'/>
                    </CardFooter>
                </Card>
            </Col>
        </Fragment>
    );
};
export default Jobs;