import React, { Fragment, useState, useEffect, useContext } from 'react';
import TableBuilder, { ApiRequestBuilder, FormOptionsBuilder, RegisteredInputsBuilder, ModalButtonBuilder, ToastBuilder, TextTitleBuilder, ModalBuilder, ModalLinkBuilder } from '../../../../../../GlobalComponents/Builders';
import buildRandomId, { buildOptionsFromArray, setInitialFormValues, sortArrayByKey } from '../../../../../../GlobalComponents/Helpers';
import { Row, Card, CardBody, Form, Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { useForm } from 'react-hook-form';
import { Col } from 'react-bootstrap';
import { AccountContext } from '../../../../../../GlobalComponents/Authentication/Accounts';
import { Navigate } from 'react-router-dom';
import moment from 'moment';
import ReactMarkdown from 'react-markdown'

const InvoiceEntryAdjusterJob = ({jobData, getJobData}) => {

    const { userSession } = useContext(AccountContext);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [renderPage, setRenderPage] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [worktypes, setWorktypes] = useState([]);
    const [billingStatuses, setBillingStatuses] = useState([]);    
    const [BasicTab, setBasicTab] = useState('0');   

    const tabList = [
        {
            title: 'Agreements',
            filter: 'Agreement'
        },
        {
            title: 'Projects',
            filter: 'Project'
        },
        {
            title: 'ProjectPhases',
            filter: 'ProjectPhase'
        },
        {
            title: 'SalesOrders',
            filter: 'SalesOrder'
        },
        {
            title: 'Tickets',
            filter: 'Ticket'
        }
    ]
    const [tabTitleCount, setTabTitleCount] = useState({});  
    
    const handleJobAction = (action, myCallback = null, formData = {'empty': 'body'}) => {

        ApiRequestBuilder('jobsJobIdActionsActionNamePost', { jobId: jobData.id, actionName: action }, { ...formData })
            .then(function(results) {
                myCallback ? myCallback(results) : null
            }
        )
    }

    const getInvoices = () => {

        handleJobAction('get-invoices-statuses', (data) => {
            if (data && data.data)
            {
                setBillingStatuses(data.data)
            }
        })

        handleJobAction('get-invoices-worktypes', (data) => {
            if (data && data.data)
            {
                setWorktypes(data.data)
            }
        })
        
        tabList.map((tab, i) => {
            handleJobAction('get-invoices-' + tab.filter, (data) => {

                if (data && data.data)
                {
                    tabTitleCount[tab.filter] = data.data.length
                    setTabTitleCount(tabTitleCount)

                    invoices[tab.filter] = data.data
                    setInvoices(invoices)
                }

                if (i + 1 === tabList.length)
                {
                    ToastBuilder('success', 'Invoices Retrieved Successfully')
                    setRenderPage(true)
                }
            })
        })
    }

    const handleTimeEntryUpdate = (formData, invoiceNumber, timeEntries) => {
        timeEntries.forEach(entry => {
            if (!(formData['worktype' + invoiceNumber + ':' + entry.id] === '' || formData['worktype' + invoiceNumber + ':' + entry.id] === 'No Change'))
            {
                handleJobAction('update-time-entry', getInvoices, { id: entry.id, worktype: formData['worktype' + invoiceNumber + ':' + entry.id]})
            }
        })
        
    }

    const handleInvoiceUpdate = (formData, invoiceId) => {
        handleJobAction('update-invoice', getInvoices, {id: invoiceId, status: formData['newInvoiceStatus' + invoiceId]})
    }

    useEffect(() => {   

        document.title = 'View Job'

        if (userSession['custom:partnerId'] !== String(jobData.partner_id) && userSession['custom:partnerId'] !== '0')
        {
            ToastBuilder('error', 'You don\'t have access to view this page')
        }
        else
        {
            getInvoices()
        }

    }, []);

    if (renderPage)
    { 
        return (
            <Fragment>
                <Row>
                    <Col xl="6">
                        <Card>
                            <CardBody>
                                <h4>Job Settings</h4>
                                <br/>
                                {jobData && jobData.destination_connection ? (<p><strong>Target Connection: </strong>{' ' + jobData.destination_connection.name}</p>) : ''}                               
                            </CardBody>
                        </Card>
                    </Col>
                </Row>     
                <Row>
                    <Container>
                        <Card>
                            <CardBody>
                                <Nav tabs>
                                    { tabList.map((tab, i) => 
                                        <NavItem key={tab.title}>
                                            <NavLink href="#" className={BasicTab === String(i) ? 'active' : ''} onClick={() => setBasicTab(String(i))}>{tab.title + ' (' + (tabTitleCount[tab.filter] ? tabTitleCount[tab.filter] : 0) + ')'}</NavLink>
                                        </NavItem>
                                    )}
                                </Nav>
                                <TabContent activeTab={BasicTab}>
                                    { tabList.map((tab, i) =>
                                        (
                                            <TabPane className="fade show" tabId={String(i)} key={tab.title + i}>
                                                <TableBuilder
                                                    data = {invoices[tab.filter]}
                                                    processing = {false}
                                                    pagination = {true}
                                                    subHeader = {true}
                                                    columnsToSearch = {['invoiceNumber']}
                                                    title = ''
                                                    columns = {[
                                                        {
                                                            name: 'Invoice #',
                                                            selector: (row) => <a key={buildRandomId()} style={{color:'blue', textDecorationLine: 'underline'}} target='_blank' href={row.url}>{row.invoiceNumber}</a>,
                                                            sortable: true,
                                                            center: true,
                                                            maxWidth: '12%'
                                                        },
                                                        {
                                                            name: 'Company',
                                                            selector: (row) => TextTitleBuilder(row.company.name),
                                                            sortable: true,
                                                            wrap: true,
                                                            maxWidth: '15%'
                                                        },
                                                        {
                                                            name: 'Date',
                                                            selector: (row) => moment(new Date(row.date)).format('YYYY-MM-DD'),
                                                            sortable: true,
                                                            center: true,
                                                            maxWidth: '12%'
                                                        },
                                                        {
                                                            name: 'Amount',
                                                            selector: (row) => <span key={buildRandomId()} title={'Applied Amount: $ ' + row.downpaymentApplied + '\nRemaining Amount: $ ' + row.remainingDownpayment}>{'$ ' + row.balance}</span>,
                                                            sortable: true,
                                                            center: true,
                                                            maxWidth: '11%'
                                                        },
                                                        {
                                                            name: 'Detail',
                                                            wrap:true,
                                                            selector: (row) => row.details ? <span key={buildRandomId()} title={row.details.title}><a key={buildRandomId()} style={{color:'blue', textDecorationLine: 'underline'}} target='_blank' href={row.details.url}>{row.details.text}</a>{' - ' + row.details.title.slice(0,50)}</span> : ''
                                                        },
                                                        {
                                                            name: tab.filter === 'SalesOrder' ? 'Products' : 'Time',  
                                                            selector: (row) => 
                                                                tab.filter === 'SalesOrder' ?
                                                                    <ModalButtonBuilder title='View' className='btn-primary' size='xl'
                                                                        body={
                                                                                <TableBuilder
                                                                                    data = {row.products}
                                                                                    processing = {false}
                                                                                    title = {'Products for Invoice #' + row.invoiceNumber}
                                                                                    columns = {[
                                                                                        {
                                                                                            name: 'Product',
                                                                                            selector: (row2) => row2.catalogItem.identifier,
                                                                                            sortable: true,
                                                                                            center: true
                                                                                        },
                                                                                        {
                                                                                            name: 'Cost',
                                                                                            selector: (row2) => '$ ' + row2.cost,
                                                                                            sortable: true,
                                                                                            center: true
                                                                                        },
                                                                                        {
                                                                                            name: 'Price',
                                                                                            selector: (row2) => '$ ' + row2.price,
                                                                                            sortable: true,
                                                                                            center: true
                                                                                        },
                                                                                        {
                                                                                            name: 'Quantity',
                                                                                            selector: (row2) => row2.quantity,
                                                                                            sortable: true,
                                                                                            center: true
                                                                                        }
                                                                                        
                                                                                    ]}
                                                                                />
                                                                            }
                                                                        />
                                                                :
                                                                    <ModalButtonBuilder title='View' className='btn-primary mb-1 mt-1' size='xl'
                                                                        formId = {'time-entry-form' + row.invoiceNumber}
                                                                        body={
                                                                            <Form id={'time-entry-form' + row.invoiceNumber} onSubmit={handleSubmit(data => handleTimeEntryUpdate(data, row.invoiceNumber, row.time))}>
                                                                                <TableBuilder
                                                                                    data = {row.time}
                                                                                    processing = {false}
                                                                                    title = {'Time Entries for Invoice #' + row.invoiceNumber}
                                                                                    columns = {[
                                                                                        {
                                                                                            name: 'Member',
                                                                                            selector: (row2) => TextTitleBuilder(row2.member.name),
                                                                                            sortable: true,
                                                                                            center: true,
                                                                                            maxWidth: '20%'
                                                                                        },
                                                                                        {
                                                                                            name: 'Hours',
                                                                                            selector: (row2) => <span title={'Start Time: ' + row2.timeStart.replace('T',' ').replace('Z','') + '\nEnd Time: ' + row2.timeEnd.replace('T',' ').replace('Z','')}>{row2.actualHours}</span>,
                                                                                            sortable: true,
                                                                                            center: true,
                                                                                            maxWidth: '4%'
                                                                                        },
                                                                                        {
                                                                                            name: 'Date',
                                                                                            selector: (row2) => row2.timeStart.slice(0, row2.timeStart.indexOf('T')),
                                                                                            sortable: true,
                                                                                            center: true,
                                                                                            maxWidth: '10%'
                                                                                        },
                                                                                        {
                                                                                            name: 'Notes',
                                                                                            selector: (row2) => row2.notes ? <ModalLinkBuilder label='View' title='Time Entry Notes' body={<ReactMarkdown>{row2.notes}</ReactMarkdown>} size='md'/> : '-', 
                                                                                            sortable: true,
                                                                                            maxWidth: '10%'
                                                                                        },
                                                                                        {
                                                                                            name: 'Worktype',
                                                                                            selector: (row2) => <div className='mt-2'>
                                                                                                <RegisteredInputsBuilder
                                                                                                    registrator={register}
                                                                                                    registratorErrors={errors}
                                                                                                    inputs={[
                                                                                                        {
                                                                                                            label: <span title={row2.workType.name + ' ' + row2.status}>{row2.workType.name}</span>,
                                                                                                            name: 'worktype' + row.invoiceNumber + ':' + row2.id,
                                                                                                            type: 'select',
                                                                                                            inputSize: 7,
                                                                                                            labelSize: 5,
                                                                                                            size: 12,
                                                                                                            className: 'mt-2',
                                                                                                            required: false,
                                                                                                            options: <FormOptionsBuilder options={buildOptionsFromArray([{name:'No Change', value:''}].concat(sortArrayByKey(worktypes, 'name')), { label: 'name', value:'id' })}/>
                                                                                                        }
                                                                                                    ]}
                                                                                                />
                                                                                            </div>,
                                                                                            center: true
                                                                                        },
                                                                                        
                                                                                    ]}
                                                                                />
                                                                            </Form>
                                                                            }
                                                                        />,
                                                            sortable: false,
                                                            center: true,
                                                            maxWidth: '12%'
                                                        },
                                                        {
                                                            name: 'Manage', 
                                                            selector: (row) => <ModalButtonBuilder title='Edit' className='btn-secondary m-1' 
                                                                onClick={setInitialFormValues(row, setValue, 'id')}
                                                                formId = {'view-/-edit-form' + row.id}
                                                                body={
                                                                    <Form id={'view-/-edit-form' + row.id} onSubmit={handleSubmit(data => handleInvoiceUpdate(data, row.id))}>
                                                                        <RegisteredInputsBuilder
                                                                            registrator={register}
                                                                            registratorErrors={errors}
                                                                            inputs={[
                                                                                {
                                                                                    label: 'Status',
                                                                                    name: 'newInvoiceStatus' + row.id,
                                                                                    type: 'select',
                                                                                    size: 12,
                                                                                    required: false,
                                                                                    options: <FormOptionsBuilder options={buildOptionsFromArray(billingStatuses, { label: 'name', value:'id' })}/>
                                                                                },
                                                                            ]}/>
                                                                    </Form>
                                                                }
                                                            />,
                                                            sortable: false,
                                                            center: true,
                                                            maxWidth: '11%'
                                                        }
                                                    ]}
                                                />
                                            </TabPane>
                                            )
                                        )}   
                                    </TabContent>
                            </CardBody>
                        </Card>
                    </Container>
                </Row>
            </Fragment>
        )
    }
    else 
    {
        if (userSession['custom:partnerId'] !== String(jobData.partner_id) && userSession['custom:partnerId'] !== '0')
        {
            return <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />
        }
        return <p>Loading Job Information...</p>
    }
};

export default InvoiceEntryAdjusterJob;