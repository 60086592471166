import React, { Fragment } from 'react';
import TableBuilder, { FormOptionsBuilder, RegisteredInputsBuilder, ModalButtonBuilder, ButtonBuilder } from '../../../../GlobalComponents/Builders';
import { buildOptionsFromArray, sortArrayByKey } from '../../../../GlobalComponents/Helpers';
import { Container, Row, Card, CardBody, Form, Modal } from 'reactstrap'
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import parse from 'html-react-parser'

const BackorderNotificationSurvey = ({toolData, jobData, connectionData, handleJobCreate}) => {
    
    const { register, handleSubmit, formState: { errors } } = useForm();

    var requiredPermissions = [
        {
            module: 'System',
            permission: 'Table Setup',
            levels: 'Add, Edit, Inquire'
        },
        {
            module: 'Finance',
            permission: 'Purchase Orders',
            levels: 'Inquire'
        }
    ]

    return (
        <Fragment>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <h4>{toolData.name}</h4>
                            <br/>
                            <h5>Description</h5>
                            {toolData.html_description ? parse(toolData.html_description) : ''}
                            <br/>
                            <h5>Permissions Required</h5>
                            <TableBuilder
                                data={requiredPermissions}
                                processing={false}
                                title = ''
                                columns = {[
                                    {
                                        name: 'Module',
                                        selector: (row) => row.module,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Permission',
                                        selector: (row) => row.permission,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Levels',
                                        selector: (row) => row.levels,
                                        sortable: true,
                                        center: true,
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <TableBuilder
                                data = {jobData}
                                processing = {false}
                                title = 'Jobs'
                                actions = {
                                    connectionData.length > 0 ? 
                                        <ModalButtonBuilder title='Create Job' className='btn-secondary'
                                            body = {
                                                <Form id='create-job-form' onSubmit={handleSubmit((data) => handleJobCreate(data, toolData))}>
                                                    <RegisteredInputsBuilder
                                                        registrator={register}
                                                        registratorErrors={errors}
                                                        inputs={[
                                                            {
                                                                label: 'Connection',
                                                                type: 'select',
                                                                size: 12,
                                                                inputSize: 6,
                                                                name: 'destinationConnection',
                                                                helpText: 'Service where updates will be made',
                                                                options: <FormOptionsBuilder options={buildOptionsFromArray(connectionData, { label: 'name', value:'id'})}/>,
                                                            }
                                                        ]}/>
                                                </Form>
                                            }
                                        /> : 
                                        <Fragment>
                                            <p><strong>You will need to create a connection before creating a job for this tool</strong> - </p>
                                            <ButtonBuilder label = 'Create Connection' onClick={() => window.location.href = process.env.PUBLIC_URL + '/connections'}/>
                                        </Fragment>
                                }
                                columns = {[
                                    {
                                        name: 'Job #',
                                        selector: (row) => row.id,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Created At',
                                        selector: (row) => row.start_time ? row.start_time : '-',
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Active',
                                        selector: (row) => 'Yes',
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Details',
                                        selector: (row) => <Link to={`${process.env.PUBLIC_URL}/tools/` + toolData.route + '/jobs/' + row.id}>{'View / Edit'}</Link>,
                                        sortable: true,
                                        center: true,
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
        </Fragment>
    )
}

export default BackorderNotificationSurvey;