import React, { Fragment, useState, useEffect } from 'react';
import TableBuilder, { ApiRequestBuilder, RegisteredInputsBuilder, ButtonBuilder, ModalButtonBuilder, TextTitleBuilder } from '../../../../../../GlobalComponents/Builders';
import { resetAllFormFields, setInitialFormValues } from '../../../../../../GlobalComponents/Helpers';
import { Container, Row, Card, CardBody, Form, Modal, Col, CardFooter } from 'reactstrap'
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

const BulkProductUpdaterJob = ({jobData}) => {
    
    let { toolId, jobId } = useParams();

    const [jobDataset, setJobDataset] = useState([]);

    const [isProcessing, setIsProcessing] = useState(true);
    const { register, handleSubmit, setValue, resetField, formState: { errors } } = useForm();

    const handleRowUpdate = (update, key = null) => {

        ApiRequestBuilder('toolsToolIdJobsJobIdDatasetsDatasetNamePatch', { toolId, jobId }, { datasetName: toolId },
            { 
                poNumber: update['poNumber' + key],
                productId: update['productId' + key],
                expectedShipDate: update['expectedShipDate' + key],
                trackingNumber: update['trackingNumber' + key],
                backordered: update['backordered' + key],
                lastUpdate: update['lastUpdate' + key],
                quantity: update['quantity' + key],
                vendor: update['vendor' + key],
                purchaseHeaderId: update['purchaseHeaderId' + key],
                purchaseDetailId: update['purchaseDetailId' + key]
            })
            .then(function(results) {
                resetAllFormFields(update, resetField)
            }
        )
    }

    const getJobDataset = () => {

        ApiRequestBuilder('toolsToolIdJobsJobIdDatasetsDatasetNameGet', { toolId, jobId, datasetName: toolId }, null, { setIsProcessing }) 
            .then(function(results) {
                if (results.data && results.data.length > 0)
                {
                    var cleanData = []

                    results.data.forEach(product => {

                        var identifier = String(product.PO_Number) + String(product.Item_ID)

                        cleanData.push({
                            identifier: identifier,
                            poNumber: product.PO_Number,
                            productId: product.Item_ID,
                            expectedShipDate: product.Expected_Ship_Date ? product.Expected_Ship_Date.split('T')[0] : '',
                            trackingNumber: product.tracking_number,
                            backordered: product.Backorder_Flag,
                            lastUpdate: product.Last_Update.split('T')[0],
                            quantity: product.Quantity,
                            vendor: product.vendor,
                            purchaseHeaderId: product.Purchase_Header_RecID,
                            purchaseDetailId: product.Purchase_Detail_RecID
                        })
                    })

                    setJobDataset(cleanData)
                }
            })
    }

    useEffect(() => {

        getJobDataset()

    }, []);

    return (
            <Fragment>
                <Row>
                    <Col xl="6">
                        <Form onSubmit={handleSubmit(() => {})}>
                            <Card>
                                <CardBody>
                                    <h4>Job Settings</h4>
                                    <br/>
                                    {jobData && jobData.destination_connection ? (<p><strong>Connection: </strong>{' ' + jobData.destination_connection.name}</p>) : ''}
                                </CardBody>
                            </Card>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Container>
                        <Card>
                            <CardBody>
                                <TableBuilder
                                    subHeader={true}
                                    columnsToSearch = {['PO_Number', 'vendor', 'Item_ID']}
                                    data = {jobDataset}
                                    processing = {isProcessing}
                                    title = 'Products'
                                    sortField = {8}
                                    sortAsc = {true}
                                    columns = {[
                                        {
                                            name: 'PO #',
                                            selector: (row) => row.poNumber,
                                            sortable: true,
                                            center: true,
                                            maxWidth:'12%'
                                        },
                                        {
                                            name: 'Product',
                                            selector: (row) => TextTitleBuilder(row.productId),
                                            sortable: true,
                                            center: true,
                                        },
                                        {
                                            name: 'Qty',
                                            selector: (row) => row.quantity,
                                            sortable: true,
                                            center: true,
                                            maxWidth:'6%'
                                        },
                                        {
                                            name: 'Vendor',
                                            selector: (row) => TextTitleBuilder(row.vendor),
                                            sortable: true,
                                            center: true,
                                        },
                                        {
                                            name: 'Ship Date',
                                            selector: (row) => row.expectedShipDate,
                                            sortable: true,
                                            center: true,
                                            maxWidth:'12%'
                                        },
                                        {
                                            name: 'Tracking #',
                                            selector: (row) => row.trackingNumber,
                                            center: true,
                                        },
                                        {
                                            name: 'Backordered',
                                            selector: (row) => row.backordered ? 'Yes' : 'No',
                                            center: true,
                                            maxWidth:'10%'
                                        },
                                        {
                                            name: 'Updated at',
                                            selector: (row) => row.lastUpdate,
                                            sortable: true,
                                            center: true,
                                            maxWidth:'10%'
                                        },
                                        {
                                            name: 'Manage', 
                                            selector: (row) => <ModalButtonBuilder title='Edit' className='btn-secondary m-1' 
                                                onClick={setInitialFormValues(row, setValue, 'identifier')}
                                                formId = {'view-/-edit-form' + row.identifier}
                                                body={
                                                    <Form id={'view-/-edit-form' + row.identifier} onSubmit={handleSubmit(data => handleRowUpdate(data, row.identifier))}>
                                                        <RegisteredInputsBuilder
                                                            registrator={register}
                                                            registratorErrors={errors}
                                                            inputs={[
                                                                {
                                                                    label: 'PO #',
                                                                    size: 12,
                                                                    type: 'static',
                                                                    name: 'poNumber' + row.identifier
                                                                },
                                                                {
                                                                    label: 'Product',
                                                                    size: 12,
                                                                    type: 'static',
                                                                    name: 'productId' + row.identifier,
                                                                },
                                                                {
                                                                    label: 'Ship Date',
                                                                    size: 12,
                                                                    type: 'date',
                                                                    name: 'expectedShipDate' + row.identifier,
                                                                },
                                                                {
                                                                    label: 'Tracking #',
                                                                    size: 12,
                                                                    name: 'trackingNumber' + row.identifier,
                                                                },
                                                                {
                                                                    label: 'Backordered',
                                                                    size: 12,
                                                                    type: 'checkbox',
                                                                    name: 'backordered' + row.identifier,
                                                                    style: { backgroundColor: 'gray' }
                                                                }
                                                            ]}/>
                                                    </Form>
                                                }
                                            />,
                                            sortable: true,
                                            center: true,
                                        }
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </Container>
                </Row>
            </Fragment>
    );
};

export default BulkProductUpdaterJob;