import { H4, H5, P, H6 } from '../../AbstractElements';
import { Form, FormGroup, Row, Col, Card } from 'reactstrap';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { RegisteredInputsBuilder, ApiRequestBuilder, ToastBuilder, ButtonBuilder } from '../Builders';
import { performStringSearch, setInitialFormValues } from '../Helpers';
import { useForm, useWatch } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import { AccountContext } from './Accounts';
import { CognitoUser } from 'amazon-cognito-identity-js'
import UserPool from './UserPool';

const Logout = () => {

    const { logout } = useContext(AccountContext);

    logout()

    return null;
}

const LoginForm = ({redirect_to}) => {

    const [showAzureSignIn, setShowAzureSignIn] = useState(false);
    const [signInEmail, setSignInEmail] = useState('');

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [email, setEmail] = useState('')
    const [stage, setStage] = useState(1)
    const [mfaUser, setMfaUser] = useState(null)

    const { authenticate } = useContext(AccountContext);

    const onLogin = async (loginForm) => {
        authenticate(loginForm.emailAddress, loginForm.password)
            .then(data => {
                setEmail(loginForm.emailAddress)
                setTimeout(() => {
                    setMfaUser(data.user)
                    if (data.status === 'authenticated') {
                        ToastBuilder('success', 'Logged In Successfully')
                        setStage(2)
                    } else if (data.status === 'totpRequired') {
                        setStage(3)
                    }
                }, 1500)
                return
            }).catch(err => { console.log('line failed', err) })
    }

    return (
        <Fragment>
          {stage === 1 && (
            <div className="login-card">
                <Form className="theme-form login-form" onSubmit={handleSubmit(onLogin)}>
                    <H4>Login</H4>
                    <H6>Welcome back! Log in to your account.</H6>
                    <RegisteredInputsBuilder 
                        registrator={register}
                        registratorErrors={errors}
                        inputs={[
                            {
                                label: 'Email Address',
                                name: 'emailAddress',
                                type: 'email',
                                size: 12,
                                onChange: (event) => { 
                                    if (event.target.value.includes('@worldoxweb.com')) 
                                    { 
                                        setShowAzureSignIn(true) 
                                        setValue('emailAddress', event.target.value)
                                        setSignInEmail(event.target.value)
                                    } 
                                    else 
                                    { 
                                        setShowAzureSignIn(false)  
                                        setValue('emailAddress', event.target.value)
                                    } 
                                },
                                required: true,
                                registrationSettings: {
                                    pattern: '^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$'
                                }
                            },
                            {
                                label: 'Password',
                                placeholder: ' ',
                                name: 'password',
                                type: 'password',
                                size: 12,
                                required: true,
                                visible: !showAzureSignIn,
                                registrationSettings: {
                                    minLength: 15,
                                    maxLength: 25
                                },
                                onChange: (event) => {
                                        setValue('password', event.target.value)
                                },
                                onKeyDown: (event) => { if (event.key === 'Enter') { handleSubmit(onLogin) } }
                            }
                        ]}
                    />
                    <FormGroup>
                        { showAzureSignIn ? 
                            <ButtonBuilder 
                                label='Sign in using Azure' 
                                onClick={() => { window.location.href = 'https://' + process.env.USER_POOL_AUTH_DOMAIN + '.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&idp_identifier=' + signInEmail.split('@')[1].trim() + '&client_id=' + process.env.REACT_APP_USER_POOL_APP_CLIENT_ID + '&redirect_uri=' + window.location.href}}/> : 
                                <ButtonBuilder className="mb-2" label='Sign in'/> }
                    </FormGroup>
                    <div className="login-social-title">
                        <H5>O</H5>
                    </div>
                    <P>Don't have account?<Link className="ms-2" to={`${process.env.PUBLIC_URL}/signup`}>Create Account</Link></P>
                    <P>Don't remember your password?<Link className="ms-2" to={`${process.env.PUBLIC_URL}/forgotPassword`}>Forgot Password</Link></P>
                </Form>
            </div>
          )}

          {stage === 2 && (
              <MultiFactorAuth user={mfaUser} redirect_to={redirect_to} email={email} step={1} />
          )}

          {stage === 3 && (
              <MultiFactorAuth user={mfaUser} redirect_to={redirect_to} email={email} step={3}/>
          )}
        </Fragment>

    );
};

const PasswordChecklist = ({control}) => {
    const password = useWatch({
        control, name: 'password'
    })

    const check_icon = <i className="fa fa-check"></i>
    const ban_icon = <i className="fa fa-ban"></i>

    const length_check = <span>{password.length >= 15 ? check_icon : ban_icon} Password has 15 characters</span>
    const special_character_check = <span>{performStringSearch(password, 'special') ? check_icon : ban_icon} Password has 1 special character</span>
    const uppercase_check = <span>{performStringSearch(password, 'uppercase') ? check_icon : ban_icon} Password has 1 uppercase letter</span>
    const lowercase_check = <span>{performStringSearch(password, 'lowercase') ? check_icon : ban_icon} Password has 1 lowercase letter</span>
    const number_check = <span>{performStringSearch(password, 'number') ? check_icon : ban_icon} Password has 1 number</span>

    return ( 
        <Fragment>
            {length_check}<br/>
            {special_character_check}<br/>
            {uppercase_check}<br/>
            {lowercase_check}<br/>
            {number_check}<br/>
        </Fragment>
    )
}

const SignUpForm = () => {

    const navigate = useNavigate();
    
    const { register, handleSubmit, control, formState: { errors } } = useForm();

    const onSignUp = (signUpFormData) => {

        if (signUpFormData.password !== signUpFormData.confirmPassword)
        {
            ToastBuilder('error', 'Your passwords do not match')
        }
        else
        {
            /**
             * When a user signs up, we have a few possible scenarios
             * 
             * 1. This is a partner user signing up for the first time.
             *      a. We will need to determine which partner they are using their email domain
             * 2. This is a client user signing up for the first time.
             *      a. We will need to determine which partner they are using the web domain they signed into
             * 3. This is a potentially random person signing up
             *      a. We will need to deny their signup unless staff has already created a user for them.
             * 
             * All of these will be handled by a lambda function prior to the initiation of the actual Cognito 
             * Sign up flow. Once initiated, it will invoke another lambda function to add the cognito username
             * into the database.
             */

            let emailAddress = signUpFormData.emailAddress
            let password = signUpFormData.password
            
            // No need to send the passwords with the request, we'll remove them here
            delete signUpFormData.password
            delete signUpFormData.confirmPassword

            ApiRequestBuilder('usersPost', null, signUpFormData).then(function(results){

                var partnerId = results.data.partner_id
                var clientId = results.data.client_id
                var userId = results.data.user_id

                /**
                 * If we successfully determined who this user is we
                 * can sign them up with Cognito
                 */
                if ([partnerId, clientId, userId].every(function(element) {return typeof element === 'number';}))
                {
                    var attributeList = [
                        {
                            Name: 'name',
                            Value: signUpFormData.firstName + ' ' + signUpFormData.lastName
                        },
                        {
                            Name: 'custom:partnerId',
                            Value: String(results.data.partner_id)
                        },
                        {
                            Name: 'custom:clientId',
                            Value: String(results.data.client_id)
                        },
                        {
                            Name: 'custom:userId',
                            Value: String(results.data.user_id)
                        },
                        {
                            Name: 'custom:emulationActive',
                            Value: 'false'
                        }
                    ];
            
                    UserPool.signUp(emailAddress, password, attributeList, [], (err, data) => {
                        if (err) {

                            switch (err.name) {
                                case 'UsernameExistsException':
                                    ToastBuilder('error', 'An account with the given email already exists.')
                                break;
                                case 'UserLambdaValidationException':
                                    ToastBuilder('error', 'This request can not be fulfilled, please try again later.')
                                break;
                                case 'InvalidPasswordException':
                                    ToastBuilder('error', 'Password must be at least 15 characters long.')
                                break;
                                case 'InvalidParameterException':
                                    console.log(err)
                                    ToastBuilder('error', 'This request cannot be fulfilled, please try again later.')
                                break;
                                default:
                                    console.log(err)
                                    ToastBuilder('info', 'Unknown Error')
                                break;
                            }
                        }
                        else 
                        {
                            navigate('/login', {replace: true});
                            ToastBuilder('success', 'Sign Up Successful, check your email to confirm your account')
                        }
                    });
                } else {
                    ToastBuilder('error', 'Unfortunately we cannot create an account for you at this time. Please contact your administrator.')
                }
            }).catch((err) => {
                console.log(err);
                ToastBuilder('error', 'Unable to Sign Up')
                ToastBuilder('info', err)
            })
        }
    }

    return (
        <Fragment>
            <div className="login-card">
                <Form className="theme-form login-form" onSubmit={handleSubmit(onSignUp)}>
                    <H4>Create Your Account</H4>
                    <H6>Enter your details to access the portal</H6>
                    <Row>
                        <RegisteredInputsBuilder 
                            registrator={register}
                            registratorErrors={errors}
                            inputs={[
                                {
                                    label: 'First Name',
                                    placeholder: 'Timothy',
                                    name: 'firstName',
                                    size: 12,
                                    required: true
                                },
                                {
                                    label: 'Last Name',
                                    placeholder: 'Turner',
                                    name: 'lastName',
                                    size: 12,
                                    required: true
                                },
                                {
                                    label: 'Email Address',
                                    name: 'emailAddress',
                                    type: 'email',
                                    size: 12,
                                    required: true,
                                    registrationSettings: {
                                        pattern: '^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$'
                                    }
                                },
                                {
                                    id: 'password',
                                    label: 'Password',
                                    placeholder: ' ',
                                    name: 'password',
                                    type: 'password',
                                    size: 12,
                                    required: true,
                                    popover_text: <PasswordChecklist control={control}/>,
                                    registrationSettings: {
                                        minLength: 15,
                                        maxLength: 25
                                    }
                                },
                                {
                                    label: 'Confirm Password',
                                    placeholder: ' ',
                                    name: 'confirmPassword',
                                    type: 'password',
                                    size: 12,
                                    labelSize: 5,
                                    inputSize: 7,
                                    required: true,
                                    registrationSettings: {
                                        minLength: 15,
                                        maxLength: 25
                                    }
                                }
                            ]}
                        />
                        <FormGroup>
                            <ButtonBuilder label='Create Account'/>
                        </FormGroup>
                        <div className="login-social-title">
                            <H5>O</H5>
                        </div>
                        <P>Already have an account?<Link className="ms-2" to={`${process.env.PUBLIC_URL}/login`}>Sign In</Link></P>
                    </Row>
                </Form>
            </div>
        </Fragment>
    );
};

const ForgotPasswordForm = () => {

    const [stage, setStage] = useState(1);
    const [email, setEmail] = useState("");
    
    const navigate = useNavigate();
    const { register, handleSubmit, control, formState: { errors } } = useForm();
  
    const getUser = (emailAddress = email) => {
      return new CognitoUser({
        Username: emailAddress.toLowerCase(),
        Pool: UserPool
      });
    };
  
    const sendCode = (email) => {

      setEmail(email['emailAddress'])

      getUser(email['emailAddress']).forgotPassword({
        onSuccess: data => {
          console.log("onSuccess:", data);
        },
        onFailure: err => {
          console.error("onFailure:", err);
          ToastBuilder('warn', 'Unable to Change Your Password, Please Try Again')
        },
        inputVerificationCode: data => {
          console.log("Input code:", data);
          setStage(2);
        }
      });
    };
  
    const resetPassword = (resetPasswordForm) => {
        
        if (resetPasswordForm.password !== resetPasswordForm.confirmPassword)
        {
            ToastBuilder('error', 'Your passwords do not match')
        }
        else
        {
            getUser().confirmPassword(resetPasswordForm.verificationCode, resetPasswordForm.password, {
                onSuccess: data => { 
                    navigate('/login', {replace: true});
                    ToastBuilder('success', 'Password Changed Successfully')
                },
                onFailure: err => {
                    console.log('Failed to change password', err)
                    navigate('/forgotPassword', {replace: true});
                    ToastBuilder('warn', 'Unable to Change Your Password, Please Try Again')
                }
            });
        };
    }
    
    return (

      <div>
        {stage === 1 && (
            <Fragment>
            <div className="login-card">
                <Form className="theme-form login-form" onSubmit={handleSubmit(sendCode)}>
                    <H4>Password Change Request</H4>
                    <H6>Please enter your email address to receive a verification code</H6>
                    <Row>
                        <RegisteredInputsBuilder 
                            registrator={register}
                            registratorErrors={errors}
                            inputs={[
                                {
                                    label: 'Email Address',
                                    placeholder: 'best-client@gmail.com',
                                    name: 'emailAddress',
                                    type: 'email',
                                    size: 12,
                                    required: true,
                                    registrationSettings: {
                                        pattern: '^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$'
                                    }
                                }
                            ]}
                        />
                        <FormGroup>
                            <ButtonBuilder label='Send code'/>
                        </FormGroup>
                        <div className="login-social-title">
                            <H5>O</H5>
                        </div>
                        <P>Nevermind, I remembered it<Link className="ms-2" to={`${process.env.PUBLIC_URL}/login`}>Sign In</Link></P>
                    </Row>
                </Form>
            </div>
        </Fragment>
        )}
  
        {stage === 2 && (
            <Fragment>
            <div className="login-card">
                <Form className="theme-form login-form" onSubmit={handleSubmit(resetPassword)}>
                    <H4>Password Change Request</H4>
                    <H6>Please enter the verification code along with your new password</H6>
                    <Row>
                        <RegisteredInputsBuilder 
                            registrator={register}
                            registratorErrors={errors}
                            inputs={[
                                {
                                    label: 'Code',
                                    placeholder: '123456',
                                    name: 'verificationCode',
                                    size: 12,
                                    required: true
                                },
                                {
                                    id: 'password',
                                    label: 'Password',
                                    placeholder: '*****',
                                    name: 'password',
                                    type: 'password',
                                    size: 12,
                                    required: true,
                                    popover_text: <PasswordChecklist control={control}/>,
                                    registrationSettings: {
                                        minLength: 15,
                                        maxLength: 25
                                    }
                                },
                                {
                                    label: 'Confirm Password',
                                    placeholder: '*****',
                                    name: 'confirmPassword',
                                    type: 'password',
                                    size: 12,
                                    labelSize: 5,
                                    inputSize: 7,
                                    required: true,
                                    registrationSettings: {
                                        minLength: 15,
                                        maxLength: 25
                                    }
                                }
                            ]}
                        />
                        <FormGroup>
                            <ButtonBuilder label='Change password'/>
                        </FormGroup>
                        <div className="login-social-title">
                            <H5>O</H5>
                        </div>
                        <P>Nevermind, I remembered it<Link className="ms-2" to={`${process.env.PUBLIC_URL}/login`}>Sign In</Link></P>
                    </Row>
                </Form>
            </div>
        </Fragment>
        )}
      </div>
    );
  };

const MultiFactorAuth = ({ user, redirect_to, email, step }) => {

    /*
        Stage 1: Ask the user to enable mfa : sends cognito token and store in db, set preferred auth SOFTWARE TOKEN.
        cognito associateSoftwareToken and associateSecretCode

        Stage 2: Request verification code sent to email using mfa_token in db.
        cognito verifySoftwareToken

        Stage 3: User has already enabled and set up mfa. Request verification code sent to email
        cognito sendMFACode
    */
    const [stage, setStage] = useState(null);

    const [initiatedMfaSetup, setInitiatedMfaSetup] = useState(false)

    const navigate = useNavigate();
    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();

    const [codeRequested, setCodeRequested] = useState(false)
    const [totpToken, setTotpToken] = useState(false)

    useEffect(() => {
        if (stage === 2 && !codeRequested) {
            setCodeRequested(true)
            requestMfaCode()
        }
        if (stage === 3 && !codeRequested) {
            setCodeRequested(true)
            requestMfaCode()
        }
    }, [stage])

    useEffect(() => {
        setStage(step)
    }, [])

    const initiateMfaSetup = () => {
        if (initiatedMfaSetup) return

        setInitiatedMfaSetup(true)
        console.log('mfa setup started')
        
        setTimeout(() => {
            setStage(2)
        }, 1500)
        user.associateSoftwareToken({
            associateSecretCode: function (token) {
                setTotpToken(token)
                ApiRequestBuilder('authMfaPost', {}, { email: email, totpToken: token })
                    .then(function (results) {
                        setTimeout(() => {
                            ToastBuilder('success', 'Email with verification code sent.')
                        }, 500)
                    })
                    .catch((err) => { })
            },
            onFailure: function (err) {
                ToastBuilder('warn', 'Successfully enabled Email MFA')
            }
        });
    };

    /* Used to authenticate the first time after enabling MFA */
    const verifySoftwareToken = (formData) => {
        if (/^\d{6}$/.test(formData.verificationCode)) {
            user.verifySoftwareToken(formData.verificationCode, 'NexNow Portal MFA', {
                onSuccess: function (result) {
                    ToastBuilder('success', 'Successfully enabled Email MFA')
                    setTimeout(() => {
                        window.location.href = process.env.PUBLIC_URL + redirect_to
                    }, 2000)
                },
                onFailure: function (err) {
                    ToastBuilder('error', 'Incorrect code entered. Please try again')
                }
            });
        } else {
            ToastBuilder('warn', 'Only 6-digit code is allowed')
        }
    };

    /*
        Uses secret in DB to generate time-based otp and email to user.
        Used by both: setup and subsequent logins
    */
    const requestMfaCode = (resend = false) => {
        ApiRequestBuilder('authMfaRequestCodePost', {}, { email: email })
        .then(function (results) {
            if(resend){
                setTimeout(() => {
                    ToastBuilder('success', `Email with verification code was resent to ${email}.`)
                }, 2000)
            }
         })
        .catch((err) => { })
    };

    /* Used to authenticate after having enabled, setup and verified software token MFA */
    const verifyCode = (formData) => {
        if (/^\d{6}$/.test(formData.verificationCode)) {
            user.sendMFACode(formData.verificationCode, {
                onSuccess: (session, userConfirmationNecessary) => {
                    ToastBuilder('success', 'User authenticated successfully.')
                    setTimeout(() => {
                        window.location.href = process.env.PUBLIC_URL + redirect_to
                    }, 2000)
                },
                onFailure: (err) => {
                    ToastBuilder('error', 'Incorrect code entered. Please try again')
                }
            }, 'SOFTWARE_TOKEN_MFA');
        }
        else {
            ToastBuilder('warn', 'Only 6-digit code is allowed')
        }
    };

    const skipMfa = () => {
        window.location.href = process.env.PUBLIC_URL + redirect_to
    };

    return (
        <Fragment>
            <div className="login-card ">
                {stage === 1 && (
                    <Row>
                        <Col xl="6">
                            <Card className='theme-form login-form'>
                                <H4>Multi-factor Authentication</H4>
                                <br />
                                <H6>Would you like to enable email authentication?</H6>
                                <br />
                                <div style={{ display: 'flex', justifyContent:'space-between' }}>
                                    <ButtonBuilder className='btn-info m-l-0' label='Not now' onClick={() => skipMfa()} />
                                    <ButtonBuilder className='btn-primary' label='Enable email mfa' onClick={() => initiateMfaSetup()} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                )}

                {stage === 2 && (
                    <Form className="theme-form login-form" id='verify-mfa-form' onSubmit={handleSubmit(verifySoftwareToken)}>
                        <H4>Verify Multi-Factor Authentication</H4>
                        <br />
                        <H6>Please enter the 6-digit verification code sent to {email}</H6>
                        <RegisteredInputsBuilder
                            registrator={register}
                            registratorErrors={errors}
                            inputs={[
                                {
                                    label: '6-digit Code',
                                    name: 'verificationCode',
                                    type: 'text',
                                    size: 12,
                                    labelSize: 5,
                                    inputSize: 7,
                                    style: { paddingLeft: '85px' },
                                    onChange: (event) => { setValue('verificationCode', event.target.value) },
                                    onKeyDown: (event) => { if (event.key === 'Enter') { handleSubmit(verifySoftwareToken) } }
                                }
                            ]}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <ButtonBuilder className='btn-secondary m-l-0 mb-1' label='Resend Code' onClick={() => requestMfaCode(true)} form={''} />
                            <ButtonBuilder label='Verify' className='btn-primary m-l-0 mb-1' />
                        </div>
                    </Form>
                )}

                {stage === 3 && (
                    <Form className="theme-form login-form" id='verify-mfa-form' onSubmit={handleSubmit(verifyCode)}>
                        <H4>Verify Multi-Factor Authentication</H4>
                        <br />
                        <H6>Please enter the 6-digit verification code sent to {email}</H6>
                        <RegisteredInputsBuilder
                            registrator={register}
                            registratorErrors={errors}
                            inputs={[
                                {
                                    label: '6-digit Code',
                                    name: 'verificationCode',
                                    type: 'text',
                                    size: 12,
                                    labelSize: 5,
                                    inputSize: 7,
                                    style: { paddingLeft: '85px' },
                                    onChange: (event) => { setValue('verificationCode', event.target.value) },
                                    onKeyDown: (event) => { if (event.key === 'Enter') { handleSubmit(verifyCode) } }
                                }
                            ]}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <ButtonBuilder className='btn-secondary m-l-0 mb-1' label='Resend Code' onClick={() => requestMfaCode(true)} form={''} />
                            <ButtonBuilder label='Verify' className='btn-primary m-l-0 mb-1' />
                        </div>
                    </Form>
                )}
            </div>
        </Fragment>
    );
};

export default LoginForm;
export { SignUpForm, Logout, ForgotPasswordForm, MultiFactorAuth };