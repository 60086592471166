import React, { Fragment, useState, useEffect, useContext } from 'react';
import TableBuilder, { ApiRequestBuilder, FormOptionsBuilder, RegisteredInputsBuilder, ModalButtonBuilder, ToastBuilder, ButtonBuilder } from '../../../../../../GlobalComponents/Builders';
import buildRandomId, { buildOptionsFromArray, uploadPresignedFile } from '../../../../../../GlobalComponents/Helpers';
import { Row, Card, CardBody, Form, CardFooter } from 'reactstrap'
import { useForm } from 'react-hook-form';
import { Col, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const ShipmentManagerJob = ({jobData, getJobData}) => {
    
    let { jobId, subpage, subpageId } = useParams();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [qualifyingTickets, setQualifyingTickets] = useState([]);
    const [processedTickets, setProcessedTickets] = useState([]);

    const getQualifyingTickets = () => {
        ApiRequestBuilder('integrationsIntegrationNameRequestPost', { integrationName: 'connectwise-manage' }, {
            jobId: jobId,
            method: 'get',
            uri: 'service/tickets',
            returnAll: true,
            timeout: 10,
            args: {
                fields: ['id', 'summary', 'contactName', 'status', 'board'],
                conditions: 'board/name IN ("Fulfillment", "Returns & Trade Ins", "Customer Support", "Repairs & Warranties") AND status/name IN ("Generate Shipping Label - to client","Generate Shipping Label - from client")'
            }
        }).then(function(results) {
            setQualifyingTickets(results.data)
        })
    }

    const getRecentlyProcessedTickets = () => {
        ApiRequestBuilder('integrationsIntegrationNameRequestPost', { integrationName: 'connectwise-manage' }, {
            jobId: jobId,
            method: 'get',
            uri: 'service/tickets',
            returnAll: true,
            timeout: 10,
            args: {
                fields: ['id', 'summary', 'contactName', 'status', 'board', '_info'],
                conditions: 'board/name IN ("Fulfillment", "Returns & Trade Ins", "Customer Support", "Repairs & Warranties") AND status/name NOT IN ("Generate Shipping Label - to client","Generate Shipping Label - from client") AND _info/updatedBy = "NexNow"'
            }
        }).then(function(results) {
            setProcessedTickets(results.data)
        })
    }

    const getTickets = () => {
        getQualifyingTickets()
        getRecentlyProcessedTickets()
    }

    useEffect(() => {   

        document.title = 'View Job'
        getTickets()

    }, []);

    return (
        <Fragment>
            <Row>
                <Col xl="6">
                    <Card>
                        <CardBody>
                            <h4>Job Settings</h4>
                            <br/>
                            {jobData && jobData.source_connection ? (<p><strong>Source Connection: </strong>{' ' + jobData.source_connection.name}</p>) : ''}   
                            {jobData && jobData.destination_connection ? (<p><strong>Target Connection: </strong>{' ' + jobData.destination_connection.name}</p>) : ''}                         
                        </CardBody>
                    </Card>
                </Col>
                <Col xl="6">
                    <Card>
                        <CardBody>
                            <h4>Actions</h4>
                            <br/>
                            <ButtonBuilder label='Generate Labels' className='m-2 col-md-3 btn-secondary' onClick={() => {
                                ApiRequestBuilder('jobsJobIdActionsActionNamePost', { jobId: jobId, actionName: 'generate-shipping-labels' })
                                    .then(function(results) {
                                        ToastBuilder('success', 'Generating Shipping Labels...')
                                        setTimeout(() => getTickets, 15000)
                                    }
                                )
                            }}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <TableBuilder
                                data = {qualifyingTickets}
                                processing = {false}
                                title = 'Qualifying Tickets'
                                columns = {[
                                    {
                                        name: 'ID',
                                        selector: (row) => row.id,
                                        sortable: true,
                                        center: true,
                                        maxWidth: '7%'
                                    },
                                    {
                                        name: 'Summary',
                                        selector: (row) => row.summary,
                                        sortable: false,
                                        center: false
                                    },
                                    {
                                        name: 'Board',
                                        selector: (row) => row.board.name,
                                        sortable: false,
                                        center: false
                                    },
                                    {
                                        name: 'Status',
                                        selector: (row) => row.status.name,
                                        sortable: false,
                                        center: false
                                    },
                                    {
                                        name: 'Contact',
                                        selector: (row) => row.contactName,
                                        sortable: false,
                                        center: false
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <TableBuilder
                                data = {processedTickets}
                                processing = {false}
                                title = 'Recently Processed Tickets'
                                columns = {[
                                    {
                                        name: 'ID',
                                        selector: (row) => row.id,
                                        sortable: true,
                                        center: true,
                                        maxWidth: '7%'
                                    },
                                    {
                                        name: 'Summary',
                                        selector: (row) => row.summary,
                                        sortable: false,
                                        center: false
                                    },
                                    {
                                        name: 'Board',
                                        selector: (row) => row.board.name,
                                        sortable: false,
                                        center: false
                                    },
                                    {
                                        name: 'Status',
                                        selector: (row) => row.status.name,
                                        sortable: false,
                                        center: false
                                    },
                                    {
                                        name: 'Contact',
                                        selector: (row) => row.contactName,
                                        sortable: false,
                                        center: false
                                    },
                                    {
                                        name: 'Processed At',
                                        selector: (row) => row._info.lastUpdated.substring(0, 10),
                                        sortable: false,
                                        center: false
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
        </Fragment>
    )
};

export default ShipmentManagerJob;